import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  getDocsFromServer,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class DraftGroupService {

  database = getFirestore();

  constructor() { }

  async createJobDraftGroup(userId, jobId, players, site, jobType, subType, sport) {
    const timestamp = serverTimestamp();
    const cleanedPlayers = await this.cleanDraftGroup(players, site, jobType, subType, sport);

    const draftGroup = {
      site: site,
      jobType: jobType,
      subType: subType,
      createdAt: timestamp,
      updatedAt: timestamp,
      players: cleanedPlayers,
    };

    const parentDocRef = doc(this.database, 'user-profiles', userId, 'job-draft-groups', jobId);
    await setDoc(parentDocRef, draftGroup);

    return parentDocRef.id;
  }


  // LATEST ADJUSTMENTS REMOVE SAVING OF THE DG
  async getJobDraftGroup(userId, jobId, sport) {
    // const parentDocRef = doc(this.database, 'user-profiles', userId, 'job-draft-groups', jobId);

    // try {
    //   const docSnapshot = await getDoc(parentDocRef);
    //   if (docSnapshot.exists()) {
    //     const data = docSnapshot.data();
    //     const draftGroup = await this.mapDraftGroup(data.players, data.site, data.jobType, data.subType, sport);
    //     return draftGroup;
    //   } else {
    //     return null;
    //   }
    // } catch (error) {
    //   console.error('Error getting document:', error);
    //   throw error;
    // }
  }

  async getDraftGroupById(site, groupId, sport, jobType, jobSubType) {
    const docRef = await doc(this.database, `${site}-draftgroups-${sport}/${groupId}`);
    const docSnap = await getDoc(docRef);
    const players = docSnap.data();
    const draftGroup = await this.cleanDraftGroup(players.data, site, jobType, jobSubType, sport.toUpperCase());
    return draftGroup;
  }

  async updateJobDraftGroup(userId, jobId, updatedPlayers) {
    const timestamp = serverTimestamp();

    const jobUpdate = {
      updatedAt: timestamp,
      players: updatedPlayers,
    }
    const docRef = await doc(this.database, 'user-profiles', userId, 'job-draft-groups', jobId);
    await updateDoc(docRef, jobUpdate);
    const updatedDocSnapshot = await getDoc(docRef);

    return updatedDocSnapshot.data();
  }


  // USER DRAFT GROUP SETS

  async createUserDraftGroupPreset(userId, sport, projectionData, draftGroupId, site, presetName) {
    this.cleanupStaleDraftGroupPresets(userId);
    const timestamp = serverTimestamp();

    const draftGroupPreset = {
      name: presetName,
      site: site,
      sport: sport,
      createdAt: timestamp,
      draftGroupId: draftGroupId,
      projectionData: projectionData,
    };

    const docRef = await addDoc(collection(this.database, "user-profiles", userId, 'user-draft-group-presets'), draftGroupPreset);
    return docRef.id;
  }

  async updateUserDraftGroupPreset(userId, presetId, projectionData) {
    const draftGroupPresetUpdate = {
      projectionData: projectionData,
    };

    const docRef = await doc(this.database, "user-profiles", userId, 'user-draft-group-presets', presetId);
    await updateDoc(docRef, draftGroupPresetUpdate);
    const updatedDocSnapshot = await getDoc(docRef);

    return updatedDocSnapshot.data();
  }

  async deleteUserDraftGroupPreset(userId, presetId) {
    const docRef = doc(this.database, "user-profiles", userId, 'user-draft-group-presets', presetId);
    try {
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error("Error deleting document:", error);
      return false;
    }
  }


  async getUserDraftGroupPresets(userId, draftGroupId) {
    const ref = collection(this.database, 'user-profiles', userId, 'user-draft-group-presets');

    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    const q = query(
      ref,
      where('draftGroupId', '==', draftGroupId),
      orderBy('createdAt', 'desc') // Sort by 'createdAt' date in descending order
    );
    const querySnapshot = await getDocs(q);

    const contests = [];
    querySnapshot.forEach((doc) => {
      const contest = {
        id: doc.id,
        ...doc.data()
      };
      contests.push(contest);
    });
    return contests;
  }

  async cleanupStaleDraftGroupPresets(userId) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'user-draft-group-presets');

    const fifteenDaysAgo = Timestamp.fromMillis(Date.now() - 15 * 24 * 60 * 60 * 1000); // Changed to 15 days
    const q = query(
      collectionRef,
      where('createdAt', '<=', fifteenDaysAgo),
      orderBy('createdAt'),
      orderBy('updatedAt', 'desc')
    );
    const querySnapshot = await getDocsFromServer(q);
    const deletePromises = querySnapshot.docs.map(async (doc) => {
      await deleteDoc(doc.ref);
    });
    await Promise.all(deletePromises);
  }


  // UTILITIES

  async cleanDraftGroup(players, site, jobType, subType, sport) {

    let playerData = players;
    let cleanedGroup = [];

    if (sport === 'NFL' && site === 'dk' && subType === 'showdown') {
      playerData = players.filter(obj => obj['Roster Position'] === 'FLEX');
    }

    const key = `${jobType}-${subType}`;

    const mapPlayer = (player, additionalFields = {}) => ({
      "Name": player.Name,
      "PlayerId": player.ID,
      "Team": player.TeamAbbrev,
      "Salary": player.Salary,
      "GameInfo": player["Game Info"],
      "Position": player.Position,
      "Projection": player?.Projection || 0,
      "StdDev": player?.StdDev || 0,
      "Ownership": player?.Ownership || 0,
      "selected": true,
      ...additionalFields,
    });

    const mapPlayerWithOdds = (player, additionalFields = {}) => ({
      "Name": player.Name,
      "PlayerId": player.ID,
      "Team": player.TeamAbbrev,
      "Salary": player.Salary,
      "GameInfo": player["Game Info"],
      "TeamMoneyline": player.TeamMoneyline || 'N/A',
      "TeamSpread": player.TeamSpread || 'N/A',
      "GameTotal": player.GameTotal || 'N/A',
      "ImpliedTeamTotal": player.TeamImpliedTotal || 'N/A',
      "Position": player.Position,
      "Projection": player?.Projection || 0,
      "StdDev": player?.StdDev || 0,
      "Ownership": player?.Ownership || 0,
      "selected": true,
      ...additionalFields,
    });

    const showdownFields = (player, siteSpecificFields = {}) => ({
      "CptnPlayerId": player.CptnPlayerId,
      "CptnOwnership": 0,
      ...siteSpecificFields
    });

    switch (sport) {
      case 'NBA': // UNVETTED
        switch (key) {
          case 'simulator-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player, { "Minutes": player?.Minutes || 0, "FieldPts": player?.FieldPts || 0 }));
            break;
          case 'optimizer-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player, { "Minutes": player?.Minutes || 0 }));
            break;
          case 'simulator-showdown':
          case 'optimizer-showdown':
            cleanedGroup = playerData.map(player => {
              const mappedPlayer = mapPlayer(player, showdownFields(player, site === 'dk' ? {} : site === 'ikb' ? {} : {
                "ProOwnership": 0,
                "StarOwnership": 0,
                "MvpOwnership": 0,

              }));
              let updatedPlayer = {};
              if (key === "optimizer-showdown") {
                updatedPlayer = {
                  ...mappedPlayer,
                  Minutes: player?.Minutes || 0,
                };
              } else {
                updatedPlayer = {
                  ...mappedPlayer,
                  Minutes: player?.Minutes || 0,
                  FieldPts: player?.FieldPts || 0,
                };
              }
              return updatedPlayer;
            });
            break;

        }
        break;
      case 'MLB':
        switch (key) {
          case 'simulator-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player, { "BattingOrder": player?.battingOrder || '', "FieldPts": player?.FieldPts || 0, "LineupStatus": player?.lineupStatus || 'N/A' }));
            break;
          case 'optimizer-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player, { "BattingOrder": player?.battingOrder || '', "LineupStatus": player?.lineupStatus || 'N/A' }));
            break;
          case 'simulator-showdown':
            cleanedGroup = playerData.map(player => mapPlayer(player, showdownFields(player, site === 'dk' ? { "BattingOrder": player?.battingOrder || '', "CptnOwnership": 0, "FieldPts": player?.FieldPts || 0, "LineupStatus": player?.lineupStatus || 'N/A' } : {
              "ProOwnership": 0,
              "StarOwnership": 0,
              "MvpOwnership": 0,
              "BattingOrder": player?.battingOrder || '',
              "FieldPts": 0,
              "LineupStatus": player?.lineupStatus || 'N/A'
            })));
            break;
          case 'optimizer-showdown':
            cleanedGroup = playerData.map(player => mapPlayer(player, showdownFields(player, site === 'dk' ? { "BattingOrder": player?.battingOrder || '', "CptnOwnership": 0 } : {
              "ProOwnership": 0,
              "StarOwnership": 0,
              "MvpOwnership": 0,
              "BattingOrder": player?.battingOrder || '',
            })));
            break;
        }
        break;
      case 'CBB':
      case 'WNBA':
        switch (key) {
          case 'simulator-classic':
          case 'optimizer-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player));
            break;
          case 'simulator-showdown':
          case 'optimizer-showdown':
            cleanedGroup = playerData.map(player => mapPlayer(player, showdownFields(player, site === 'dk' ? {} : {
              "ProOwnership": 0,
              "StarOwnership": 0,
              "MvpOwnership": 0,
            })));
            break;
        }
        break;
      case 'PGA':
        switch (key) {
          case 'simulator-classic':
          case 'simulator-showdown':
            cleanedGroup = playerData.map(player => mapPlayer(player, { "FieldPts": 0 }));
            break;
          case 'optimizer-classic':
          case 'optimizer-showdown':
          case 'optimizer-tournament-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player));
            break;
          case 'simulator-tournament-classic':
            cleanedGroup = playerData.map(player => mapPlayer(player, { "WinProb": 0, "MakeCut": 0, "FieldPts": 0 }));
            break;
          // case 'optimizer-tournament-classic':
          // cleanedGroup = playerData.map(player => mapPlayer(player, { "WinProb": 0, "MakeCut": 0 }));
          // break;
        }
        break;
      case 'TEN':
        cleanedGroup = playerData.map(player => mapPlayer(player, { "WinProb": player.WinProb || 0, "FieldPts": player?.FieldPts || 0 }));
        break;
      case 'MMA':
        switch (key) {
          case 'simulator-classic':
          case 'simulator-showdown':
            cleanedGroup = playerData.map(player => {
              const mappedPlayer = mapPlayer(player, { "WinProb": 0, "KOProb": 0, "FieldPts": 0 });
              delete mappedPlayer.StdDev;
              return mappedPlayer;
            });
            break;
          case 'optimizer-classic':
          case 'optimizer-showdown':
            cleanedGroup = playerData.map(player => {
              const mappedPlayer = mapPlayer(player, { "WinProb": 0, "KOProb": 0 });
              delete mappedPlayer.StdDev;
              return mappedPlayer;
            });
            break;
        }
        break;
      case 'NAS':
        switch (key) {
          case 'simulator-classic':
          case 'simulator-showdown':
            cleanedGroup = playerData.map(player => {
              const mappedPlayer = mapPlayer(player, { "WinProb": 0, "StartPos": player['Start Position'] || 0, "FieldPts": 0 });
              delete mappedPlayer.StdDev;
              return mappedPlayer;
            });
            break;
          case 'optimizer-classic':
          case 'optimizer-showdown':
            cleanedGroup = playerData.map(player => {
              const mappedPlayer = mapPlayer(player, { "WinProb": 0, "StartPos": player['Start Position'] || 0 });
              delete mappedPlayer.StdDev;
              return mappedPlayer;
            });
            break;
        }
        break;
      case 'NFL':
        switch (key) {
          case 'simulator-classic':
            cleanedGroup = playerData.map(player => mapPlayerWithOdds(player, { "FieldPts": player?.FieldPts || 0, }));
            break;
          case 'simulator-showdown':
            if (site === 'dk') {
              cleanedGroup = playerData.map(player => {
                const mappedPlayer = mapPlayerWithOdds(player, {
                  "FieldPts": player?.FieldPts || 0,
                  'CptnOwnership': player?.CptnOwnership || 0,
                });

                const { ...rest } = mappedPlayer;
                const reorderedPlayer = {
                  "Name": rest.Name,
                  "PlayerId": rest.PlayerId,
                  "CptnPlayerId": player.CptnPlayerId,
                  ...rest
                };

                return reorderedPlayer;
              });
            } else {
              cleanedGroup = playerData.map(player => {
                const mappedPlayer = mapPlayerWithOdds(player, {
                  "FieldPts": player?.FieldPts || 0,
                  'MvpOwnership': player?.MvpOwnership || 0,
                });

                const { ...rest } = mappedPlayer;
                const reorderedPlayer = {
                  "Name": rest.Name,
                  "PlayerId": rest.PlayerId,
                  "CptnPlayerId": player.CptnPlayerId,
                  ...rest
                };

                return reorderedPlayer;
              });
            }
            break;
          case 'optimizer-classic':
            cleanedGroup = playerData.map(player => mapPlayerWithOdds(player));
            break;
          case 'optimizer-showdown':
            if (site === 'dk') {
              cleanedGroup = playerData.map(player => {
                const mappedPlayer = mapPlayerWithOdds(player, {
                  'CptnOwnership': player?.CptnOwnership || 0,
                });

                const { ...rest } = mappedPlayer;
                const reorderedPlayer = {
                  "Name": rest.Name,
                  "PlayerId": rest.PlayerId,
                  "CptnPlayerId": player.CptnPlayerId,
                  ...rest
                };

                return reorderedPlayer;
              });
            } else {
              cleanedGroup = playerData.map(player => {
                const mappedPlayer = mapPlayerWithOdds(player, {
                  'MvpOwnership': player?.MvpOwnership || 0,
                });

                const { ...rest } = mappedPlayer;
                const reorderedPlayer = {
                  "Name": rest.Name,
                  "PlayerId": rest.PlayerId,
                  "CptnPlayerId": player.CptnPlayerId,
                  ...rest
                };

                return reorderedPlayer;
              });
            }
            break;
        }
    }
    return cleanedGroup;
  }


  // function not needed if we don't go back to saving dg to seperate place in the db.
  // async mapDraftGroup(players, site, jobType, subType, sport) {
  //   const key = `${jobType}-${subType}`;

  //   const commonFields = [
  //     "Name", "PlayerId", "Position", "Team", "Salary",
  //     "GameInfo", "Projection", "StdDev", "Ownership",
  //     "FieldPts", "selected"
  //   ];

  //   const showdownFields = {
  //     dk: [
  //       "CptnPlayerId", "CptnOwnership"
  //     ],
  //     ikb: [
  //       "CptnPlayerId", "CptnOwnership"
  //     ],
  //     default: [
  //       "CptnPlayerId", "ProOwnership",
  //       "StarOwnership", "MvpOwnership"
  //     ]
  //   };

  //   const sportSpecificFields = {
  //     NBA: ["Minutes"],
  //     MLB: ["BattingOrder"],
  //     PGA: ["MakeCut", "WinProb"],
  //     TEN: ["WinProb"],
  //     MMA: ["WinProb", "KOProb"]
  //   };

  //   const getMappedGroup = (extraFields = []) => players.map(player => {
  //     const mappedPlayer = {};
  //     commonFields.concat(extraFields).forEach(field => {
  //       mappedPlayer[field] = player[field] || (field === 'BattingOrder' ? '' : undefined);
  //     });
  //     return mappedPlayer;
  //   });

  //   let extraFields = sportSpecificFields[sport] || [];

  //   if (key.includes('showdown')) {
  //     if (site === 'dk') {
  //       extraFields = extraFields.concat(showdownFields.dk);
  //     } else if (site === 'ikb') {
  //       extraFields = extraFields.concat(showdownFields.ikb);
  //     } else {
  //       extraFields = extraFields.concat(showdownFields.default);
  //     }
  //   }

  //   return getMappedGroup(extraFields);
  // };

}
