import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { Subscription } from 'rxjs';
import { MLB_TEAM_LOGO_IDS } from 'src/app/constants/team-images/MLB/mlb-team-image-map';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'side-panel-teams',
  templateUrl: './side-panel-teams.component.html',
  styleUrls: ['./side-panel-teams.component.scss']
})
export class SidePanelTeamsComponent {
  @Input() jobData: any;
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;

  isSortDesc: boolean = true;
  isSearchActive: boolean;
  parentFilters: any = [];

  teamsWithFilters: any = [];
  teamFiltersApplied: number = 0;
  showApplyFilters: boolean;
  totalExposureFilters: number = 0;
  filteredOutTeams: any;
  isLoadingLatestValues: boolean;

  isPrimaryStack: boolean = true;

  selectedStackLengthFiltersTeamName: any;
  selectedStackLengthFilters: any;

  awaitFilterChange: boolean;

  private subscription: Subscription;

  constructor(
    private stateService: StateService,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.subscription = this.stateService.entryBuilderFilters$.subscribe(
      value => {
        if (value.length) {
          this.parentFilters = value;
          setTimeout(() => {
            this.handleExistingTeamFilters();
          }, 200);
        }
        if (this.awaitFilterChange) {
          this.awaitFilterChange = false;
          this.parentFilters = value;
          setTimeout(() => {
            this.handleExistingTeamFilters();
          }, 50);
        }
      }
    );
  }

  onToggleStackList() {
    this.isPrimaryStack = !this.isPrimaryStack;
    if (this.isPrimaryStack) {
      this.jobData.currentTeams.sort((a, b) => b.primaryGlobalExposure - a.primaryGlobalExposure)
    } else {
      this.jobData.currentTeams.sort((a, b) => b.secondaryGlobalExposure - a.secondaryGlobalExposure)
    }
  }

  resetSearch() {
    this.isSearchActive = false;
    this.searchInput.nativeElement.value = '';
  }

  onClickHighestToLowest() {
    this.resetSearch();
    const sortedTeams = this.jobData.currentTeams.sort((a, b) => b.primaryGlobalExposure - a.primaryGlobalExposure);
    this.jobData.currentTeams = sortedTeams;
    this.isSortDesc = true;
  }

  onClickLowestToHighest() {
    this.resetSearch();
    const sortedTeams = this.jobData.currentTeams.sort((a, b) => a.primaryGlobalExposure - b.primaryGlobalExposure);
    this.jobData.currentTeams = sortedTeams;
    this.isSortDesc = false;
  }

  onSearchTeam(event) {
    const query = event.target.value;
    this.isSearchActive = true;

    if (query.length === 0) {
      this.onClickHighestToLowest();
      return;
    }

    this.jobData.currentTeams.sort((a, b) => {
      const similarityA = this.calculateFuzzyMatchScore(a.name, query);
      const similarityB = this.calculateFuzzyMatchScore(b.name, query);

      return similarityB - similarityA;
    });
  };

  calculateFuzzyMatchScore(str1, str2) {

    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    if (str1.includes(str2)) {
      return str2.length / str1.length;
    }
    return 0;
  };

  get filteredTeams() {
    return this.jobData?.currentTeams?.filter(team => team.name === this.jobData?.selectedExposureTeam) || [];  /// NOT SURE ABOUT THIS FOR TEAMS YET
  }

  onClickSortLineupsByTeam(name) {
    const stackProp = this.isPrimaryStack ? 'primaryStack' : 'secondaryStack';
    this.jobData.currentLineups.sort((a, b) => {
      const aIncludes = a[stackProp].includes(`${name} `) ? 1 : 0;
      const bIncludes = b[stackProp].includes(`${name} `) ? 1 : 0;
      return bIncludes - aIncludes;
    });
    this.jobData.teamHighlightName = name;
  }

  containsPlayer(obj, playerName): boolean {
    // Extract player-related keys dynamically
    const playerKeys = Object.keys(obj).filter(key => typeof obj[key] === 'string' && key !== 'stack');

    return playerKeys.some(key => (obj[key] as string).includes(playerName));
  }

  sortPlayerDataByPlayerName(playerName) {
    alert('waiting to see how this works for teams')
    return this.jobData.currentLineups.sort((a, b) => {
      const aContainsPlayer = this.containsPlayer(a, playerName);
      const bContainsPlayer = this.containsPlayer(b, playerName);
      // Sort such that objects containing the player come first
      if (aContainsPlayer && !bContainsPlayer) {
        return -1;
      } else if (!aContainsPlayer && bContainsPlayer) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onClickClearFocus() {
    this.jobData.selectedExposureTeam = null;
  }

  onClickLockInTeam(team) {
    if (this.isPrimaryStack) {
      if (team.minPrimaryExposure == 100) {
        team.minPrimaryExposure = null;
        team.maxPrimaryExposure = null;
      } else {
        team.minPrimaryExposure = 100;
        team.maxPrimaryExposure = null;
      }
    } else {
      if (team.minSecondaryExposure == 100) {
        team.minSecondaryExposure = null;
        team.maxSecondaryExposure = null;
      } else {
        team.minSecondaryExposure = 100;
        team.maxSecondaryExposure = null;
      }
    }
    this.handleTeamChange();
  }

  onClickRemoveTeam(team) {
    if (this.isPrimaryStack) {
      if (team.maxPrimaryExposure == 0) {
        team.minPrimaryExposure = null;
        team.maxPrimaryExposure = null;
      } else {
        team.minPrimaryExposure = null;
        team.maxPrimaryExposure = 0;
      }
    } else {
      if (team.maxSecondaryExposure == 0) {
        team.minSecondaryExposure = null;
        team.maxSecondaryExposure = null;
      } else {
        team.minSecondaryExposure = null;
        team.maxSecondaryExposure = 0;
      }
    }
    this.handleTeamChange();
  }

  handleTeamChange() {
    const currentTeamsState = this.jobData.currentTeams;
    const teamWithFilterValues = currentTeamsState.filter((t) => t.maxPrimaryExposure !== null || t.minPrimaryExposure !== null || t.maxSecondaryExposure !== null || t.minSecondaryExposure !== null);
    this.teamsWithFilters = teamWithFilterValues;
    if (this.teamsWithFilters.length > 0) {
      this.showApplyFilters = true;
    } else {
      this.showApplyFilters = false;
    }
  }

  async onClickApplyFilters() {
    this.isLoadingLatestValues = true;
    this.teamFiltersApplied = this.teamsWithFilters.length;
    const teamFilters = await this.generateTeamFilters();
    const filterMessage = {
      isReset: false,
      teamFilters: teamFilters,
    }
    this.stateService.setTeamExposureFilters(filterMessage);
    this.showApplyFilters = false;
  }

  onClickResetExposureFilters() {
    this.isLoadingLatestValues = true;
    this.teamFiltersApplied = 0;
    this.teamsWithFilters = null;
    this.awaitFilterChange = true;
    this.zone.run(() => { });
    const filterMessage = {
      isReset: true,
      teamFilters: [],
    }
    this.stateService.setTeamExposureFilters(filterMessage);
  }

  async generateTeamFilters() {
    const filterSet = [];

    const addFilter = (filterType, filterValue, operator, value) => {
      if (value !== null) {
        filterSet.push({
          filterType,
          filterValue,
          operator,
          value
        });
      }
    };

    const addStackLengthFilters = (team, stackLengths, filterType) => {
      stackLengths.forEach((stack) => {
        const filterValue = `${team.name.toUpperCase()}_${stack.name.replace(' ', '_').toUpperCase()}`;
        addFilter(filterType, filterValue, 'less-than-or-equal-to', stack.max);
        addFilter(filterType, filterValue, 'greater-than-or-equal-to', stack.min);
      });
    };

    this.teamsWithFilters.forEach((team) => {
      addFilter('primaryGlobalTeamExposure', team.name, 'less-than-or-equal-to', team.maxPrimaryExposure);
      addFilter('primaryGlobalTeamExposure', team.name, 'greater-than-or-equal-to', team.minPrimaryExposure);
      addFilter('secondaryGlobalTeamExposure', team.name, 'less-than-or-equal-to', team.maxSecondaryExposure);
      addFilter('secondaryGlobalTeamExposure', team.name, 'greater-than-or-equal-to', team.minSecondaryExposure);

      addStackLengthFilters(team, team.primaryStackLengths, 'primaryTeamStackLength');
      addStackLengthFilters(team, team.secondaryStackLengths, 'secondaryTeamStackLength');
    });

    return filterSet;
  }


  handleExistingTeamFilters() {
    this.filteredOutTeams = [];
    const existingExposureFilters = this.parentFilters.filter((f) =>
      f.filterType === 'primaryGlobalTeamExposure' ||
      f.filterType === 'secondaryGlobalTeamExposure' ||
      f.filterType === 'primaryTeamStackLength' ||
      f.filterType === 'secondaryTeamStackLength'
    );

    this.totalExposureFilters = existingExposureFilters.length;
    const currentTeams = [...this.jobData.currentTeams];
    existingExposureFilters.forEach((f) => {
      const teamFilterValue = f.filterValue;
      if (teamFilterValue) {
        const matchingTeam = currentTeams.find((t) => t.name === teamFilterValue);
        if (matchingTeam) {
          switch (f.filterType) {
            case 'primaryGlobalTeamExposure':
              if (f.operator === 'greater-than-or-equal-to') {
                matchingTeam.minPrimaryExposure = f.value;
              } else {
                matchingTeam.maxPrimaryExposure = f.value;
              }
              break;
            case 'secondaryGlobalTeamExposure':
              if (f.operator === 'greater-than-or-equal-to') {
                matchingTeam.minSecondaryExposure = f.value;
              } else {
                matchingTeam.maxSecondaryExposure = f.value;
              }
              break;
            // case 'primaryTeamStackLength':
            //   const matchingPrimaryStack = matchingTeam.primaryStackLengths.find((sl) => sl.name.replace(/\s+/g, '') === f.filterValue)
            //   if (f.operator === 'greater-than-or-equal-to') {
            //     matchingPrimaryStack.min = f.value;
            //   } else {
            //     matchingPrimaryStack.max = f.value;
            //   }
            //   break;
            // case 'primaryTeamStackLength':
            //   const matchingSecondaryStack = matchingTeam.secondaryStackLengths.find((sl) => (teamFilterValue + '_' + sl.name.replace(/\s+/g, '_')).toUpperCase() === f.filterValue)
            //   if (f.operator === 'greater-than-or-equal-to') {
            //     matchingSecondaryStack.min = f.value;
            //   } else {
            //     matchingSecondaryStack.max = f.value;
            //   }
            //   break;

          }

        } else {
          const object = {
            id: f.id,
            nameValue: f.filterValue,
            operator: f.operator,
            value: f.value,
          }
          this.filteredOutTeams.push(object);
        }
      }

    });
    this.jobData.currentTeams = currentTeams;
    this.zone.run(() => { });
    this.isLoadingLatestValues = false;
  }

  getTeamImg(name) {
    switch (this.jobData.sport) {
      case 'MLB':
        const teamId = MLB_TEAM_LOGO_IDS[name] || 'default-id';
        return `https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${teamId}.svg`;
      case 'NFL':
        return `https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${name}.png`;
      case 'NBA':
        return `https://storage.googleapis.com/ace-mind-sport-logos/nba-team-logos/${name}.svg`;
      default:
        console.log('no team img generation match found');
    }
  }

  onClickExpandStackLengthFilters(team) {
    this.selectedStackLengthFiltersTeamName = team.name;
  }


  onClickSortLineupsByStackLengthType(team: string, stackLength: string) {
    const stackLengthMap = {
      'Two Stack': '2',
      'Three Stack': '3',
      'Four Stack': '4',
      'Five Stack': '5',
    };

    const stackValue = stackLengthMap[stackLength];
    if (!stackValue) return;

    this.jobData.currentLineups.sort((a, b) => {
      const aIncludes = a.lineupStack.includes(`${team} ${stackValue}`) ? 1 : 0;
      const bIncludes = b.lineupStack.includes(`${team} ${stackValue}`) ? 1 : 0;
      return bIncludes - aIncludes;
    });

    this.jobData.stackLengthHighlightTeam = `${team} ${stackLength}`;
  }

  onChangeStackLengthFilter() {
    const currentTeamsState = this.jobData.currentTeams;
    const teamWithFilterValues = currentTeamsState.filter((t) =>
      t.primaryStackLengths.some((s) => s.max) !== null ||
      t.primaryStackLengths.some((s) => s.min) !== null ||
      t.secondaryStackLengths.some((s) => s.max) !== null ||
      t.secondaryStackLengths.some((s) => s.min) !== null)
    this.teamsWithFilters = teamWithFilterValues;
    if (this.teamsWithFilters.length > 0) {
      this.showApplyFilters = true;
    } else {
      this.showApplyFilters = false;
    }
  }

  collapseStackLengthFilters() {
    this.selectedStackLengthFiltersTeamName = null;
  }

  ngOnDestroy() {
    this.stateService.clearTeamExposureFilters();
  }


}