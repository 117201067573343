const BASE_COLUMNS = [
    { prop: 'Name', name: 'Name' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'Projection', name: 'Projection', editable: true },
    { prop: 'Ownership', name: 'Ownership', editable: true },
    { prop: 'WinProb', name: 'Win Prob', suffix: "%", editable: true, tooltip: '0-100 percentage chance that player wins' },
    { prop: 'StartPos', name: 'Start Pos', editable: true, tooltip: 'Starting position for the driver' },
];

const SIM_COLUMNS = [
    ...BASE_COLUMNS,
    { prop: 'FieldPts', name: 'Field Projection', editable: true },
];

export const NASCAR_TABLES_SIM = {
    projections_showdown_dk: SIM_COLUMNS,
    projections_showdown_fd: SIM_COLUMNS,
    projections_classic: SIM_COLUMNS,
};

export const NASCAR_TABLES_OPTO = {
    projections_showdown_dk: BASE_COLUMNS,
    projections_showdown_fd: BASE_COLUMNS,
    projections_classic: BASE_COLUMNS,
};