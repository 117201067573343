<div class="ss-dash-page-wrapper">
  <div class="ss-dash-card">
    <div class="dash-card-header">
      <div class="dash-card-title">Your Results</div>
      <div class="dash-card-time-prompt" *ngIf="allUniversalJobs">
        Last {{ allUniversalJobs.length > 11 ? '50' : '10' }}
      </div>
    </div>

    <div
      class="dash-card-body mt32 flex"
      style="justify-content: center"
      *ngIf="isLoading || isLoadingJobs"
    >
      <div class="ss-spinner page-spinner"></div>
    </div>

    <div class="dash-card-body" *ngIf="!isLoadingJobs" style="overflow: hidden;">
      <div *ngIf="allUniversalJobs && allUniversalJobs.length == 0">
        <div class="empty-wrapper">
          <svg-icon
            [svgStyle]="{ 'height.px': 88 }"
            src="/assets/illustrations/empty-template.svg"
          ></svg-icon>
          <div class="message">No Recent Results to Display</div>
        </div>
      </div>

      <div
        class="template-row"
        *ngFor="let j of allUniversalJobs; let i = index"
      >
        <div class="template-item">
          <div class="info">
            <div (click)="onClickJob(j)" class="cursor-p" style="margin-right: 8px">
              <svg-icon
                *ngIf="j.site === 'dk'"
                [svgStyle]="{ 'height.px': 32 }"
                src="/assets/logos/dk/dk-logo-icon.svg"
              ></svg-icon>
              <svg-icon
                *ngIf="j.site === 'fd'"
                [svgStyle]="{ 'height.px': 32 }"
                src="/assets/logos/fd/fd-logo-icon.svg"
              ></svg-icon>
              <svg-icon
                *ngIf="j.site === 'ikb'"
                [svgStyle]="{ 'height.px': 32 }"
                src="/assets/logos/ikb/ikb-logo.svg"
              ></svg-icon>
            </div>
            <div>
              <div class="job-name" (click)="onClickJob(j)">
                {{ j.sport }}
                {{ j.jobType === 'simulator' ? 'Simulator' : 'Optimizer' }} -
                {{
                  j.site === 'dk'
                    ? 'DraftKings'
                    : j.site === 'fd'
                    ? 'FanDuel'
                    : 'IKB'
                }}
                - <span class="cap-it-bro">{{ j.jobSubType }}</span>
              </div>
              <div class="job-detail" (click)="onClickJob(j)">
                {{ j.slate }}
                <span *ngIf="j?.contest"> | {{ j.contest.name }} </span>
              </div>
              <div
                class="job-detail"
                *ngIf="j.createdAt && j.createdAt.toDate"
              >
                Created:
                {{ j.createdAt.toDate() | date : 'MM/dd/yyyy hh:mm a' }}
              </div>

              <div class="job-detail" [ngClass]="{'detail-link' : j?.description == null || j?.description == '' }" (click)="onClickManageJobDescription(j, i)">
               {{ j?.description ? 'Notes:' + ' ' + j?.description : 'Add Notes' }}
              </div>
            </div>
          </div>
          <div class="ss-job-list-status">
            <div
              class="icon-utility-btn"
              style="margin-right: 12px"
              (click)="onClickManageJobDescription(j, i)"
            >
              <svg-icon
                [svgStyle]="{ 'height.px': 14 }"
                src="/assets/icons/info-note.svg"
              ></svg-icon>
            </div>
            <div class="status-chip" (click)="onClickJob(j)">
              {{ translateStatus(j.status) }}
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="allUniversalJobs.length >= 10 && allUniversalJobs.length < 12"
      >
        <button
          class="ss-secondary-btn expanded"
          (click)="onClickViewMoreJobs()"
        >
          <span *ngIf="!isLoadingMore">View More</span>
          <div class="ss-spinner btn-spinner" *ngIf="isLoadingMore"></div>
        </button>
      </div>
    </div>
  </div>
</div>

<ace-base-modal
  modalHeader="Edit Job Notes"
  (handleModalClose)="onClickCancelManageJobDescription()"
  *ngIf="selectedJob && !isLoadingJobs"
>
  <div class="description-input-row">
    <textarea
      class="ss-input expanded description-area"
      placeholder="Good for quick reference of what you were trying to achieve in your results"
      [(ngModel)]="jobDescriptionString"
      [disabled]="isSavingDescription"
      maxlength="500"
    ></textarea>
  </div>

  <div class="modal-tools-row">
    <button
      class="ss-primary-btn mb16"
      (click)="onClickSaveJobDescription(selectedJob)"
      [disabled]="isSavingDescription"
    >
      Save Notes
    </button>
    <button
      class="ss-secondary-btn"
      (click)="onClickCancelManageJobDescription()"
    >
      Cancel
    </button>
  </div>
</ace-base-modal>
