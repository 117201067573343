export const handleOptimizerSeed = async (data) => {
    const jobData = data;
    console.log('the starting job data is', jobData);
    const csvString = await generateCSV(jobData.currentLineups, jobData);
    // set the seed data
    const seedData = {
        jobId: jobData.jobId,
        optimizerDraftGroupId: jobData.draftGroupId,
        jobSubType: jobData.jobSubType,
        optoLineupCsvString: csvString,
    }
    jobData.optimizerSeed = seedData;

    // set the basic sim values needed
    jobData.jobType = 'simulator'
    jobData.createdAt = Date.now();
    jobData.updatedAt = Date.now();
    jobData.options = null;
    jobData.jobProgressLog = null;
    jobData.status = 'building';

    // delete the unnessesary data
    delete jobData.currentMatches;
    delete jobData.currentLineups;
    delete jobData.currentPlayers;
    delete jobData.playerExposures;
    delete jobData.csvId;
    delete jobData.jobId;
    delete jobData.optoResult;
    delete jobData.tableId;

    console.log('the finished job data is', jobData);

    return jobData;

}

const generateCSV = async (lineups, jobData) => {
    const rows = [];

    // Get headers and position map based on jobData
    const { headers, positionMap } = await getHeadersAndMap(jobData);

    // Add the headers as the first row
    rows.push(headers.join(','));

    lineups.forEach(lineup => {
        const usedKeys = new Set();
        const row = headers.map(header => {
            const keys = positionMap[header] || [];
            
            // Find the first available value in the keys list that hasn't been used yet
            for (const key of keys) {
                if (lineup.hasOwnProperty(key) && !usedKeys.has(key)) {
                    usedKeys.add(key); // Mark this key as used
                    return lineup[key]; // Return the matched value
                }
            }
            return ''; // Return empty string if no value is found
        });

        rows.push(row.join(',')); // Join row values with commas
    });

    return rows.join('\n'); // Join all rows with newlines
};


// Function to generate headers and positionMap
const getHeadersAndMap = async (jobData) => {
    const key = `${jobData.sport.toLowerCase()}-${jobData.jobSubType}-${jobData.site}`;
    let headers = [];
    let positionMap = {};

    switch (key) {
        case 'mma-classic-dk':
        case 'mma-showdown-dk':
        case 'mma-classic-fd':
        case 'mma-showdown-fd':
            headers = ["F", "F", "F", "F", "F", "F"];
            positionMap = {
                "F": ["f1", "f2", "f3", "f4", "f5", "f6"]
            };
            break;
        case 'nfl-classic-dk':
        case 'nfl-classic-fd':
            headers = ["QB", "RB", "RB", "TE", "WR", "WR", "WR", "FLEX", "DST"];
            positionMap = {
                "QB": ["qb"],
                "RB": ["rb1", "rb2"],
                "TE": ["te"],
                "WR": ["wr1", "wr2", "wr3"],
                "FLEX": ["flex"],
                "DST": ['dst'],
            };
            break;
        case 'nfl-showdown-dk':
            headers = ["CPT", "FLEX", "FLEX", "FLEX", "FLEX", "FLEX"];
            positionMap = {
                "CPT": ["cpt"],
                "FLEX": ["flex1", "flex2", "flex3", "flex4", "flex5"],
            };
            break;
        case 'nfl-showdown-fd':
            headers = ["MVP", "FLEX", "FLEX", "FLEX", "FLEX"];
            positionMap = {
                "MVP": ["mvp"],
                "FLEX": ["flex1", "flex2", "flex3", "flex4"],
            };
            break;
        case 'pga-classic-dk':
        case 'pga-showdown-dk':
        case 'pga-classic-fd':
        case 'pga-showdown-fd':
            headers = ["G", "G", "G", "G", "G", "G"];
            positionMap = {
                "G": ["g1", "g2", "g3", "g4", "g5", "g6"]
            };
            break;
        case 'mlb-classic-dk':
        case 'mlb-classic-fd':
            headers = ["P", "P", "C/1B", "2B", "3B", "SS", "OF", "OF", "OF", "UTIL"];
            positionMap = {
                "P": ["p1", "p2"],
                "C/1B": ["c1b"],
                "2B": ["2b"],
                "3B": ["3b"],
                "SS": ["ss"],
                "OF": ["of1", "of2", "of3"],
                "UTIL": ["util"],
            };
            break;
        case 'mlb-showdown-dk':
            headers = ["CPT", "UTIL", "UTIL", "UTIL", "UTIL", "UTIL"];
            positionMap = {
                "CPT": ["cpt"],
                "UTIL": ["util1", "util2", "util3", "util4", "util5"],
            };
            break;
        case 'mlb-showdown-fd':
            headers = ["MVP", "UTIL", "UTIL", "UTIL", "UTIL"];
            positionMap = {
                "MVP": ["mvp"],
                "UTIL": ["util1", "util2", "util3", "util4"],
            };
            break;

        // NBA
        case 'nba-classic-dk':
            headers = ["PG", "SG", "SF", "PF", "C", "G", "F", "UTIL"];
            positionMap = {
                "PG": ["pg"],     
                "SG": ["sg"],     
                "SF": ["sf"],     
                "PF": ["pf"],     
                "C": ["c"],               
                "G": ["g"],
                "F": ["f"],
                "UTIL": ["util"]          
            };            
            break;
        case 'nba-classic-fd':
            headers = ["PG", "SG", "SF", "PF", "C"];
            positionMap = {
                "PG": ["pg1", "pg2"],
                "SG": ["sg1", "sg2"],
                "SF": ["sf1", "sf2"],
                "PF": ["pf1", "pf2"],
                "C": ["c"]
            };            
            break;
        case 'nba-showdown-dk':
            headers = ["CPT", "UTIL1", "UTIL2", "UTIL3", "UTIL4", "UTIL5"];
            positionMap = {
                "CPT": ["cpt"],
                "UTIL": ["util1", "util2", "util3", "util4", "util5"]
            };
            break;
        case 'nba-showdown-fd':
            headers = ["MVP", "STAR", "PRO", "UTIL1", "UTIL2"];
            positionMap = {
                "MVP": ["mvp"],
                "STAR": ["star"],
                "PRO": ["pro"],
                "UTIL": ["util1", "util2"]
            };
            break;

        default:
            throw new Error(`No mapping found for key: ${key}`);
    }

    return { headers, positionMap };
};








