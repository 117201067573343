export const NBA_TABLES_SIM = {
    projections_showdown_dk: [
        { prop: 'Name', name: 'Name' },
        { prop: 'Position', name: 'Position' },
        { prop: 'Salary', name: 'Salary' },
        { prop: 'GameInfo', name: 'Game Info', matchup: true },
        { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
        { prop: 'Minutes', name: 'Minutes', editable: true, tooltip: 'Player’s expected number of minutes in the game' },
        { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
        { prop: 'Ownership', name: 'FLEX Ownership', editable: true, tooltip: 'Expected UTIL/FLEX Ownership Percentage for the Selected Contest. Used for Lineup Building' },
        { prop: 'CptnOwnership', name: 'CPTN Ownership', editable: true, tooltip: 'Expected Captain/MVP Ownership Percentage for Selected Contest. Used for Lineup Building' },
        { prop: 'FieldPts', name: 'Field Points', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
    ],
    projections_showdown_fd: [
        { prop: 'Name', name: 'Name' },
        { prop: 'Position', name: 'Position' },
        { prop: 'Salary', name: 'Salary' },
        { prop: 'GameInfo', name: 'Game Info', matchup: true },
        { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
        { prop: 'Minutes', name: 'Minutes', editable: true, tooltip: 'Player’s expected number of minutes in the game' },
        { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
        { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: null },
        { prop: 'ProOwnership', name: 'ProOwnership', editable: true, tooltip: null },
        { prop: 'StarOwnership', name: 'StarOwnership', editable: true, tooltip: null },
        { prop: 'MvpOwnership', name: 'MvpOwnership', editable: true, tooltip: null },
        { prop: 'FieldPts', name: 'Field Points', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
    ],
    projections_classic: [
        { prop: 'Name', name: 'Name' },
        { prop: 'Position', name: 'Position' },
        { prop: 'Salary', name: 'Salary' },
        { prop: 'GameInfo', name: 'Game Info', matchup: true },
        { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
        { prop: 'Minutes', name: 'Minutes', editable: true, tooltip: 'Player’s expected number of minutes in the game' },
        { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
        { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: 'Expected Ownership Percentage for the Selected Contest. Used for Lineup Building' },
        { prop: 'FieldPts', name: 'Field Points', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
    ]
}

export const NBA_TABLES_OPTO = {
    projections_showdown_dk: [
        { prop: 'Name', name: 'Name' },
        { prop: 'Position', name: 'Position' },
        { prop: 'Salary', name: 'Salary' },
        { prop: 'GameInfo', name: 'Game Info', matchup: true },
        { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
        { prop: 'Minutes', name: 'Minutes', editable: true, tooltip: 'Player’s expected number of minutes in the game' },
        { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
        { prop: 'Ownership', name: 'FLEX Ownership', editable: true, tooltip: 'Expected UTIL/FLEX Ownership Percentage for the Selected Contest. Used for Lineup Building' },
        { prop: 'CptnOwnership', name: 'CPTN Ownership', editable: true, tooltip: 'Expected Captain/MVP Ownership Percentage for Selected Contest. Used for Lineup Building' },
    ],
    projections_showdown_fd: [
        { prop: 'Name', name: 'Name' },
        { prop: 'Position', name: 'Position' },
        { prop: 'Salary', name: 'Salary' },
        { prop: 'GameInfo', name: 'Game Info', matchup: true },
        { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
        { prop: 'Minutes', name: 'Minutes', editable: true, tooltip: 'Player’s expected number of minutes in the game' },
        { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
        { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: null },
        { prop: 'ProOwnership', name: 'ProOwnership', editable: true, tooltip: null },
        { prop: 'StarOwnership', name: 'StarOwnership', editable: true, tooltip: null },
        { prop: 'MvpOwnership', name: 'MvpOwnership', editable: true, tooltip: null },
    ],
    projections_classic: [
        { prop: 'Name', name: 'Name' },
        { prop: 'Position', name: 'Position' },
        { prop: 'Salary', name: 'Salary' },
        { prop: 'GameInfo', name: 'Game Info', matchup: true },
        { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
        { prop: 'Minutes', name: 'Minutes', editable: true, tooltip: 'Player’s expected number of minutes in the game' },
        { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
        { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: 'Expected Ownership Percentage for the Selected Contest. Used for Lineup Building' },
    ]
}