<div *ngIf="isLoading" class="ss-base-page-loading-wrapper">
  <div class="ss-spinner page-spinner"></div>
</div>

<div *ngIf="!isLoading">
  <div class="ss-base-card ss-job-control-wrapper p16">
    <div class="ss-job-control-wrapper">
      <div class="job-controls-wrapper">
        <div class="optimizer-seed-info" *ngIf="jobData.optimizerSeed">
          <div class="star">
            <svg-icon
              [svgStyle]="{ 'height.px': 18 }"
              src="/assets/icons/power-chip.svg"
            ></svg-icon>
          </div>
          <div class="title-row">
            <div class="title">Sim Optimized Lineups</div>
          </div>
          <tool-tip
            explanation="Your lineups have been loaded and will be considered in the sim. You can only select contests with this draft group of placers. Click the broom icon to clear this data."
          ></tool-tip>
        </div>

        <div class="select-wrapper" *ngIf="!jobData?.optimizerSeed">
          <select
            class="ss-input"
            id="jobData.jobType"
            [(ngModel)]="jobData.jobType"
            (change)="onChangeJobType($event)"
          >
            <option value="simulator">Simulator</option>
            <option value="optimizer">Optimizer</option>
          </select>
        </div>

        <div class="select-wrapper" *ngIf="!jobData?.optimizerSeed">
          <select
            class="ss-input"
            id="sport"
            [(ngModel)]="jobData.sport"
            (change)="onChangeSport($event)"
          >
            <option value="NBA">NBA</option>
            <option value="CBB">CBB</option>
            <option value="MLB">MLB</option>
            <!-- <option value="NAS">NASCAR</option> -->
            <option value="TEN">TEN</option>
            <option value="PGA">PGA</option>
            <option value="MMA">MMA</option>
            <option value="NFL">NFL</option>
          </select>
        </div>

        <div class="select-wrapper" *ngIf="!jobData?.optimizerSeed">
          <select
            class="ss-input"
            id="site"
            [(ngModel)]="jobData.site"
            (change)="onChangeSite($event)"
          >
            <option value="dk">DraftKings</option>
            <option value="fd">FanDuel</option>
            <!-- <option *ngIf="jobData.sport === 'NBA' || jobData.sport === 'MLB'"
              value="ikb">IKB</option> -->
          </select>
        </div>

        <div class="select-wrapper" *ngIf="!jobData?.optimizerSeed">
          <select
            #subTypeSelect
            class="ss-input"
            id="subType"
            [(ngModel)]="jobData.jobSubType"
            (change)="onChangeContestType(subTypeSelect)"
          >
            <option name="classic" value="classic">Classic</option>
            <option name="showdown" value="showdown">Showdown</option>
          </select>
        </div>

        <div
          class="select-wrapper"
          *ngIf="
            jobData.jobSubType == 'classic' &&
            !jobData?.optimizerSeed &&
            !isLoadingSiteData
          "
        >
          <select
            class="ss-input"
            id="contestSlate"
            (change)="onSelectSlate($event)"
            [(ngModel)]="jobData.slateId"
          >
            <option value="undefined" disabled>Select Slate</option>
            <option
              *ngIf="classicSlates.length === 0"
              value="undefined"
              disabled
            >
              None Available
            </option>
            <option
              *ngFor="let slate of classicSlates"
              [value]="slate.gameSetKey"
            >
              {{ slate.name }}
              <span *ngIf="slate.gameCount">
                - {{ slate.gameCount }} Games</span
              >
            </option>
          </select>
        </div>

        <div
          class="select-wrapper"
          *ngIf="
            jobData.jobSubType == 'showdown' &&
            !jobData?.optimizerSeed &&
            !isLoadingSiteData
          "
        >
          <select
            class="ss-input"
            id="contestSlate"
            (change)="onSelectSlate($event)"
            [(ngModel)]="jobData.slateId"
          >
            <option value="undefined" disabled>Select Slate</option>
            <option
              *ngIf="showdownSlates.length === 0"
              value="undefined"
              disabled
            >
              None Available
            </option>
            <option
              *ngFor="let slate of showdownSlates"
              [value]="slate.gameSetKey"
            >
              {{ slate.name }}
              <span *ngIf="slate.matchup"> - {{ slate.matchup }}</span>
            </option>
          </select>
        </div>

        <typeahead
          #typeaheadComponent
          *ngIf="
            contestSlate &&
            !isDraftGroupOutOfDate &&
            !isLoadingSiteData &&
            jobData.jobType === 'simulator'
          "
          [items]="contestSlate?.contests"
          [showItemBadge]="false"
          (itemSelected)="onSelectContest($event)"
          placeholder="Contests..."
          isAbsolutePositionList="true"
          style="z-index: 1000"
        ></typeahead>

        <div
          style="transform: translate(-2px, 6px)"
          *ngIf="isLoadingSiteData || isLoadingSlate"
        >
          <div class="ss-spinner input-row-loader"></div>
        </div>
      </div>

      <div class="job-utility-row-wrap">
        <div
          class="tool-btn"
          style="margin-left: 14px"
          (click)="showResetJob = !showResetJob"
        >
          <svg-icon
            class="icon"
            [svgStyle]="{ 'height.px': 18 }"
            src="/assets/icons/broom-icon.svg"
          ></svg-icon>
          <div class="text">Reset Data</div>
        </div>
        <button
          class="ss-primary-btn run-btn"
          [disabled]="isCreatingJob"
          (click)="onClickRunJob()"
        >
          <span *ngIf="!isCreatingJob">Run</span>
          <div class="ss-spinner btn-spinner-sm" *ngIf="isCreatingJob"></div>
        </button>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="p16" *ngIf="isMissingDraftGroupData">
    <app-message-prompt
      type="warning auth"
      mb="24px"
      messageTitle="Draft Group Data Missing"
      messageBody="Our source data for this draft group is incomplete. We've received an alert and will fix it ASAP. We post to Discord when these issues are fixed! In most cases within the hour."
    >
    </app-message-prompt>
  </div>

  <div
    class="empty-state-anim-wrap"
    *ngIf="(jobData.draftGroup == null || isLoadingSlate) && !isLoading"
  >
    <empty-state-skull-animation
      [isLoading]="isLoadingSlate"
    ></empty-state-skull-animation>
  </div>

  <div class="job-creator-grid" [ngClass]="{ 'hide-settings': !showSettings }">
    <div
      class="job-creator-projections-wrapper"
      *ngIf="jobData.draftGroup && !isLoadingSlate"
    >
      <draft-group-projections
        #projectionsComponent
        *ngIf="jobData.draftGroup"
        [jobData]="jobData"
      ></draft-group-projections>
    </div>

    <div
      class="job-creator-config-wrapper"
      *ngIf="jobData.draftGroup && !isLoadingSlate"
    >
      <div
        class="job-exception-wrapper"
        *ngIf="showSettings && jobExceptions.length && jobData.slateId"
      >
        <div class="exception-header">
          <div class="exception-header-fade"></div>
          <div class="left-section">
            <div class="icon">
              <svg-icon
                [svgStyle]="{ 'height.px': 24 }"
                src="/assets/icons/octo-warning.svg"
              ></svg-icon>
            </div>
            <div class="exception-header-text">THINGS TO FIX</div>
          </div>
          <div class="close-icon" (click)="jobExceptions = []">
            <svg-icon
              class="icon"
              [svgStyle]="{ 'height.px': 20 }"
              src="/assets/icons/close-square.svg"
            ></svg-icon>
          </div>
        </div>
        <div class="exception-body">
          <div class="instructions">
            To generate lineups, you need to handle the following data items:
          </div>
          <div class="exception-item" *ngFor="let e of jobExceptions">
            {{ e }}
          </div>
        </div>
      </div>

      <job-configuration-section
        [jobData]="jobData"
        [jobExceptions]="jobExceptions"
        [isContestStarted]="isContestStarted"
        (handleHideConfig)="handleCollapseSettings()"
      ></job-configuration-section>

      <div *ngIf="!showSettings" class="closed-panel-label">
        Config and Tools
      </div>
    </div>
  </div>
</div>

<base-edit-overlay
  *ngIf="showResetJob"
  (closeOverlay)="handleCloseResetJob()"
  width="27rem"
>
  <div class="ss-upgrade-modal-wrapper">
    <div class="logo">
      <svg-icon
        [svgStyle]="{ 'height.px': 88 }"
        src="/assets/logos/ace-mind-logo-mono.svg"
      ></svg-icon>
    </div>
    <div class="title">Reset Tool?</div>
    <div class="sub-title">This will clear all current data and options.</div>
    <button class="ss-secondary-btn mt24" (click)="resetTempJobData()">
      Yeah, clear the data
    </button>
  </div>
</base-edit-overlay>

<div class="opto-sim-modal-wrapper" *ngIf="showOptoSimModal">
  <div class="opto-sim-modal">
    <div class="opto-sim-header">
      <div class="header-text">Optimized Sim</div>
    </div>
    <div class="import-body">
      <div class="lineups-progress-row">
        <div class="ss-job-progress-log-wrapper">
          <div class="progress-line">
            <div class="text">Optimized Lineups Detected</div>
          </div>

          <div class="progress-line">
            <div class="ss-spinner" *ngIf="isLoadingOptomizedLineups"></div>
            <div class="text">Adding Optimized Lineups to Lineups Panel</div>
          </div>

          <div class="progress-line" *ngIf="isPreparingOptoToSim">
            <div class="text">Cleaning up</div>
          </div>

          <div class="progress-line" *ngIf="isPreparingOptoToSim">
            <div class="ss-spinner" *ngIf="isPreparingOptoToSim"></div>
            <div class="text">Getting available contests for lineups</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mobile-notice-wrapper" *ngIf="showMobileNotice">
  <div class="mobile-notice-content">
    <div class="mobile-notice-header">
      AceMind Delivers More on Larger Displays
    </div>
    <div class="mobile-notice-message">
      When we updated to our UI, we chose to focus on larger screen sizes. Our analytics show most users leverage laptop/desktop.
    </div>
    <div class="mobile-notice-message">
      While we have made the app responsive for your mobile device, we highly recommend a larger screens for best results.  
    </div>

    <button class="ss-secondary-btn mt24" (click)="onAcceptMobileNotice()">
      Understood
    </button>
  </div>
</div>
