import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeParentheses'
})
export class RemoveParenthesesPipe implements PipeTransform {
    transform(value: string): string {
      if (value) {
        return value.replace(/\([^)]*\)/g, '').trim();
      } else {
        return 'Configuration Missing';
      }
    }
  }