<div>
  <div class="config-tab-control">
    <div class="tab-links">
      <div
        class="link"
        (click)="onToggleGroup('settings')"
        [class.active]="activeGroup === 'settings'"
      >
        Settings
      </div>
      <div
        class="link 2"
        (click)="onToggleGroup('player_rules')"
        [class.active]="activeGroup === 'player_rules'"
      >
        Players
      </div>
      <div
        class="link 2"
        (click)="onToggleGroup('team_rules')"
        [class.active]="activeGroup === 'team_rules'"
      >
        Teams
      </div>
      <div
      class="link 2"
      (click)="onToggleGroup('matchup_rules')"
      [class.active]="activeGroup === 'matchup_rules'"
    >
      Matchups
    </div>
    <div
    class="link 2"
    (click)="onToggleGroup('position_rules')"
    [class.active]="activeGroup === 'position_rules'"
  >
    Positions
  </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'settings'">
    <div class="centered-form">
      <section *ngIf="jobData.jobSubType">
        <presets-manager
          [showSavePreset]="showSavePreset"
          [isDefaultApplied]="isDefaultApplied"
          [presetName]="presetName"
          [isSavingPreset]="isSavingPreset"
          [selectedPreset]="selectedPreset"
          [valuesDirty]="valuesDirty"
          [showPresets]="showPresets"
          [availablePresets]="availablePresets"
          [jobData]="jobData"
          [makePresetDefaultIndex]="makePresetDefaultIndex"
          [deletePresetIndex]="deletePresetIndex"
          [isMakingPresetDefault]="isMakingPresetDefault"
          [isDeletingPreset]="isDeletingPreset"
          [defaultPresetExists]="defaultPresetExists"
          (onClickShowPresets)="onClickShowPresets()"
          (onClickUpdateExistingPreset)="onClickUpdateExistingPreset()"
          (onClickShowSave)="onClickShowSave()"
          (onSaveJobPreset)="onSaveJobPreset($event.name)"
          (onCancelSavePreset)="onCancelSavePreset()"
          (onClickRestoreDefaults)="onClickRestoreDefaults()"
          (onSelectOptionPreset)="onSelectOptionPreset($event.data, $event.id)"
          (onClickSetPresetDefault)="
            onClickSetPresetDefault($event.id, $event.index, $event.data)
          "
          (onClickRemovePresetDefault)="
            onClickRemovePresetDefault($event.id, $event.index, $event.data)
          "
          (onRemovePreset)="onRemovePreset($event.id, $event.index)"
        ></presets-manager>
      </section>

      <hr style="margin-bottom: 12px; margin-top: 24px" />

      <div class="inline-inputs">
        <input-group
          label="Number of Lineups"
          tooltip="Number of lineups you want to generate"
          [(ngModel)]="jobData.options.numLineups"
          [min]="0"
          [max]="5000"
          [errorMessage]="'Value between 0 and 5,000'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.numLineups < 0 ||
              jobData.options.numLineups == null ||
              jobData.options.numLineups > 5000
          }"
        >
        </input-group>

        <input-group
          label="Min Unique Players"
          tooltip="Determines the minimum number of players that will be different between each lineup"
          [(ngModel)]="jobData.options.numUniques"
          [min]="1"
          [max]="5"
          [errorMessage]="'Value between 1 and 5'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.numUniques < 1 ||
              jobData.options.numUniques == null ||
              jobData.options.numUniques > 5
          }"
        >
        </input-group>

        <input-group
          label="Projection Minimum"
          tooltip="Projection minimum to be used in generating lineups"
          [(ngModel)]="jobData.options.projectionMinimum"
          [min]="0"
          [errorMessage]="'This field is required'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.projectionMinimum < 0 ||
              jobData.options.projectionMinimum == null
          }"
        >
        </input-group>

        <input-group
        label="Minutes Minimum"
        tooltip="Minutes minimum to be used in generating lineups"
        [(ngModel)]="jobData.options.minutesMinimum"
        [min]="0"
        [errorMessage]="'This field is required'"
        [isInline]="true"
        (valueChange)="onValueChange({ userChange: true })"
        [ngClass]="{
          error:
            jobData.options.minutesMinimum < 0 ||
            jobData.options.minutesMinimum == null
        }"
      >
      </input-group>

        <input-group
          label="Randomness"
          tooltip="Blanket randomness applied to all players' stddev in game simulations -- 100 means leave player stddevs as is"
          [(ngModel)]="jobData.options.randomness"
          [min]="0"
          [max]="1000"
          [errorMessage]="'Enter a value between 0 and 1000'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.randomness < 0 ||
              jobData.options.randomness > 1000 ||
              jobData.options.randomness == null
          }"
        >
        </input-group>

        <input-group
          label="Minimum Lineup Salary"
          tooltip="Minimum salary to be used by the field generator. The closer this value is to the max salary the longer it takes for the generator to find lineups"
          [(ngModel)]="jobData.options.minLineupSalary"
          [min]="0"
          [max]="siteSalaryMax"
          [errorMessage]="'Enter a value between 0 and ' + siteSalaryMax"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.minLineupSalary < 0 ||
              jobData.options.minLineupSalary > siteSalaryMax ||
              jobData.options.minLineupSalary == null
          }"
        >
        </input-group>

      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'player_rules'">
    <div class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header" style="margin-bottom: 8px">
          Player Rules
        </div>
        <div class="import-setting-link" (click)="onClickImportPlayerRules()">
          Import Recent
        </div>
      </div>

      <hr style="margin-bottom: 20px" />

      <div class="rule-wrapper" *ngFor="let rule of jobData.rules; let i = index">
        <div class="info">
          <div class="title">
            <span
              >{{ rule.type === 'at-least' ? 'At Least' : 'At Most' }} -
              {{ rule.value }}</span
            >
          </div>
          <div class="players-wrapper">
            <div class="player" *ngFor="let player of rule.players">
              {{ player.name }}
            </div>
          </div>
        </div>
        <div class="icon" (click)="onClickRemoveRule(i)">
          <svg-icon
            class="icon"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/icons/close-square.svg"
          ></svg-icon>
        </div>
      </div>

      <button
        class="ss-utility-btn secondary fluid"
        (click)="onClickAddRule()"
        *ngIf="!showRuleForm"
      >
        Add a Rule
      </button>

      <div *ngIf="showRuleForm">
        <div class="input-row">
          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select class="ss-input expanded" id="type" [(ngModel)]="ruleType">
              <option value="at-least">At Least</option>
              <option value="at-most">At Most</option>
            </select>
          </div>

          <div class="ss-form-group">
            <label for="contestType">Number:</label>
            <input
              appSelectAllText
              class="ss-input expanded"
              id="value"
              placeholder="Number"
              type="number"
              maxlength="100"
              [(ngModel)]="ruleValue"
            />
          </div>
        </div>

        <div class="staged-players" *ngIf="rulePlayers.length > 0">
          <div
            class="player-chip"
            *ngFor="let player of rulePlayers; let i = index"
          >
            <div class="text">
              {{ player.name }}
            </div>
            <div class="icon" (click)="onClickRemoveStagedPlayer(i)">
              <svg-icon
                class="icon"
                [svgStyle]="{ 'height.px': 18 }"
                src="/assets/icons/close-square.svg"
              ></svg-icon>
            </div>
          </div>
        </div>

        <player-type-ahead
          [items]="searchablePlayers"
          (itemSelected)="onSelectPlayer($event)"
          label="Select Player:"
          placeholder="Search Players..."
        ></player-type-ahead>

        <div class="base-error-text mb16">
          {{ ruleError }}
        </div>

        <div class="save-row">
          <div class="actions">
            <button
              class="ss-utility-btn secondary"
              (click)="onClickCancelRule()"
            >
              Cancel
            </button>
            <button
              class="ss-utility-btn"
              (click)="onClickSaveRule()"
              [disabled]="rulePlayers.length === 0 || !ruleValue"
            >
              Add Rule
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'team_rules'">
    <div
      class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header" style="margin-bottom: 8px">
          Team Rules
        </div>
        <div class="import-setting-link" (click)="onClickImportTeamRules()">
          Import Recent
        </div>
      </div>

      <hr style="margin-bottom: 20px" />

      <div class="rule-wrapper" *ngFor="let rule of jobData.teamRules; let i = index">
        <div class="info">
          <div class="title" style="margin-bottom: 0">
            <span
              >{{ rule.team }} -
              {{ rule.type === 'team-limit' ? 'Limit' : 'At Least' }}:
              {{ rule.value }}</span
            >
          </div>
        </div>
        <div class="icon" (click)="onClickRemoveTeamRule(i)">
          <svg-icon
            class="icon"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/icons/close-square.svg"
          ></svg-icon>
        </div>
      </div>

      <button
        class="ss-utility-btn secondary fluid"
        (click)="onClickAddTeamRule()"
        *ngIf="!showTeamRuleForm"
      >
        Add a Rule
      </button>

      <div *ngIf="showTeamRuleForm">
        <div class="input-row">
          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select
              class="ss-input expanded"
              id="type"
              [(ngModel)]="teamRuleType"
            >
              <option value="team-limit">Team Limit</option>
              <option value="team-at-least">Team at Least</option>
            </select>
          </div>

          <div class="ss-form-group">
            <label for="contestType">Number:</label>
            <input
              appSelectAllText
              class="ss-input expanded"
              id="value"
              placeholder="Number"
              type="number"
              maxlength="100"
              [(ngModel)]="teamRuleValue"
            />
          </div>
        </div>

        <typeahead
          [items]="availableTeams"
          (itemSelected)="onSelectTeam($event)"
          label="Select Team:"
          placeholder="Search Teams..."
        ></typeahead>

        <div class="base-error-text mb16">
          {{ teamRuleError }}
        </div>

        <div class="save-row">
          <div class="actions">
            <button
              class="ss-utility-btn secondary"
              (click)="onClickCancelTeamRule()"
            >
              Cancel
            </button>
            <button
              class="ss-utility-btn"
              (click)="onClickSaveTeamRule()"
              [disabled]="!teamRuleValue || !teamRuleTeam"
            >
              Add Rule
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'matchup_rules'">
    <div class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header" style="margin-bottom: 8px;">
          Matchup Rules
        </div>
        <div class="import-setting-link" (click)="onClickImportMatchupRules()">Import Recent</div>
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="rule-wrapper" *ngFor="let rule of jobData.matchupRules; let i = index">
        <div class="info">
          <div class="title" style="margin-bottom: 0;">
            <span>{{rule.matchup}} - {{rule.type === 'matchup-limit' ? 'Limit' : 'At Least' }}: {{rule.value}}</span>
          </div>
        </div>
        <div class="icon" (click)="onClickRemoveMatchupRule(i)">
          <svg-icon class="icon" [svgStyle]="{ 'height.px':20 }" src="/assets/icons/close-square.svg"></svg-icon>
        </div>
      </div>

      <button class="ss-utility-btn secondary fluid" (click)="onClickAddMatchupRule()"
        *ngIf="!showMatchupRuleForm">Add a
        Rule</button>

      <div *ngIf="showMatchupRuleForm">

        <div class="input-row">

          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select class="ss-input expanded" id="type" [(ngModel)]="matchupRuleType">
              <option value="matchup-limit">Matchup Limit</option>
              <option value="matchup-at-least">Matchup at Least</option>
            </select>
          </div>

          <div class="ss-form-group">
            <label for="contestType">Number:</label>
            <input appSelectAllText class="ss-input expanded" id="value" placeholder="Number" type="number"
              maxlength="100" [(ngModel)]="matchupRuleValue" />
          </div>

        </div>

        <typeahead [items]="availableMatches" (itemSelected)="onSelectMatchup($event)" label="Select Matchup:"
          placeholder="Search Matchups..."></typeahead>

        <div class="base-error-text mb16">
          {{matchupRuleError}}
        </div>

        <div class="save-row">
          <div class="actions">
            <button class="ss-utility-btn secondary" (click)="onClickCancelMatchupRule()">Cancel</button>
            <button class="ss-utility-btn" (click)="onClickSaveMatchupRule()"
              [disabled]="!matchupRuleValue || !matchupRuleMatch">Add Rule</button>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'position_rules'">
    <div class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header" style="margin-bottom: 8px;">
          Positional Rules
        </div>
        <div class="import-setting-link" (click)="onClickImportStackRules()">Import Recent</div>
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="rule-wrapper" *ngFor="let rule of jobData.stackRules?.pair; let i = index">
        <div class="info">
          <div class="title" style="margin-bottom: 0;">
            <span>Pair: {{rule.value}} - {{rule.key}} - {{rule.scope}}</span>
          </div>
          <div class="players-wrapper mt8">
            <div class="player">With:&nbsp;</div>
            <div class="player" *ngFor="let p of rule.positions">{{p}}</div>
          </div>
          <div class="players-wrapper mt8" *ngIf="rule.excludeTeams.length > 0">
            <div class="player">Exclude Teams:&nbsp;</div>
            <div class="player" *ngFor="let e of rule.excludeTeams">{{e}}</div>
          </div>
        </div>
        <div class="icon" (click)="onClickRemoveStackRulePair(i)">
          <svg-icon class="icon" [svgStyle]="{ 'height.px':20 }" src="/assets/icons/close-square.svg"></svg-icon>
        </div>
      </div>

      <div class="rule-wrapper" *ngFor="let rule of jobData.stackRules?.limit; let i = index">
        <div class="info">
          <div class="title" style="margin-bottom: 0;">
            <span>Limit: {{rule.value}} - {{rule.scope}}</span>
          </div>
          <div class="players-wrapper mt8">
            <div class="player">Limit Positions:&nbsp;</div>
            <div class="player" *ngFor="let p of rule.positions">{{p}}</div>
          </div>
          <div class="players-wrapper mt8" *ngIf="rule.unlessPositions.length > 0">
            <div class="player">Unless Positions - </div>
            <div class="player">{{rule.unlessPositionsType}}:&nbsp;</div>
            <div class="player" *ngFor="let p of rule.unlessPositions">{{p}}</div>
          </div>
          <div class="players-wrapper mt8" *ngIf="rule.excludeTeams.length > 0">
            <div class="player">Exclude Teams:&nbsp;</div>
            <div class="player" *ngFor="let e of rule.excludeTeams">{{e}}</div>
          </div>
        </div>
        <div class="icon" (click)="onClickRemoveStackRuleLimit(i)">
          <svg-icon class="icon" [svgStyle]="{ 'height.px':20 }" src="/assets/icons/close-square.svg"></svg-icon>
        </div>
      </div>

      <button class="ss-utility-btn secondary fluid" (click)="onClickAddStackRule()" *ngIf="!showStackRulesForm">Add a
        Rule</button>

      <div *ngIf="showStackRulesForm">

        <div class="input-row">

          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select class="ss-input expanded" id="type" [(ngModel)]="stackRuleType">
              <option value="pair">Pair Position</option>
              <option value="limit">Limit Players</option>
            </select>
          </div>

          <div class="ss-form-group" *ngIf="stackRuleType === 'pair' ">
            <label for="contestSlate">{{ jobData.contestType === '84' ? 'CPT Position' : 'Position:' }}</label>
            <select class="ss-input expanded" id="type" [(ngModel)]="stackRuleKey">
              <option value="QB">QB</option>
              <option value="RB">RB</option>
              <option value="WR">WR</option>
              <option value="TE">TE</option>
              <option value="DST">DST</option>
            </select>
          </div>
        
          </div>
          <div class="input-row">

          <div class="ss-form-group">
            <label for="value">{{stackRuleType === 'pair' ? 'With:' : 'Limit to:'}}</label>
            <input appSelectAllText class="ss-input expanded" id="value" placeholder="Number" type="number"
              maxlength="100" [(ngModel)]="stackRuleValue" />
          </div>

          <div class="ss-form-group">
            <label for="contestSlate">From:</label>
            <select class="ss-input expanded" id="type" [(ngModel)]="stackRuleScope">
              <option value="same-team">Same Team</option>
              <option value="opp-team">Opposing Team</option>
              <option value="same-game">Same Game</option>

            </select>
          </div>

        </div>


        <pill-type-ahead [items]="[{name: 'QB'}, {name:'RB'}, {name:'WR'}, {name:'TE'}, {name:'DST'}]"
          [selectedItems]="stackRulePositions" (itemSelected)="onSelectStackPosition($event)"
          (itemRemoved)="onRemoveStackPosition($event)"
          label="{{ jobData.contestType === '84' ? 'Of These FLEX Positions:' : 'Of These Positions:' }}"
          placeholder="Position..."></pill-type-ahead>

        <pill-type-ahead [items]="availableTeams" [selectedItems]="stackRuleExcludeTeams"
          (itemSelected)="onSelectStackTeam($event)" (itemRemoved)="onRemoveStackTeam($event)"
          label="Excluding these Teams:" placeholder="Team..."></pill-type-ahead>

        <div class="flex" *ngIf="stackRuleType === 'limit' " style="gap: 1em">

          <pill-type-ahead [items]="[{name: 'QB'}, {name:'RB'}, {name:'WR'}, {name:'TE'}, {name:'DST'}]"
            [selectedItems]="stackRuleUnlessPositions" (itemSelected)="onSelectStackUnlessPosition($event)"
            (itemRemoved)="onRemoveStackUnlessPosition($event)" label="Unless Paired With:" placeholder="Position..."
            style="width: 100%"></pill-type-ahead>

          <div class="ss-form-group" style="width: 370px;">
            <label for="contestSlate">From:</label>
            <select class="ss-input" id="type" [(ngModel)]="stackRulesUnlessPositionsScope" style="width: 100%;">
              <option value="same-team">Same Team</option>
              <option value="opp-team">Opposing Team</option>
              <option value="same-game">Same Game</option>
            </select>
          </div>

        </div>


        <div class="base-error-text mb16">
          {{stackRulesError}}
        </div>

        <div class="save-row">
          <div class="actions">
            <button class="ss-utility-btn secondary" (click)="onClickCancelStackRule()">Cancel</button>
            <button class="ss-utility-btn" (click)="onClickSaveStackRule()"
              [disabled]="!stackRuleValue || stackRulePositions.length === 0">Add Rule</button>
          </div>
        </div>

      </div>

    </div>
  </div>

  <!-- Z IMPORTER -->
  <rules-importer *ngIf="showImportModal" [userId]="jobData.userId" [jobType]="jobData.jobType"
        [sport]="jobData.sport" [draftGroupId]="jobData.contest ? jobData.contest.draftGroupId : jobData.draftGroupId"
        (handleImportPlayerRules)="handleImportPlayerRules($event)"
        (handleImportTeamRules)="handleImportTeamRules($event)"
        (handleImportMatchupRules)="handleImportMatchupRules($event)"
        (handleImportStackRules)="handleImportStackRules($event)" [importMode]="importMode"
        (handleCloseImportModal)="handleCloseImportModal()"></rules-importer>

</div>
