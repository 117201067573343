import { Component, Input, OnInit } from '@angular/core';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, updateMetadata } from "firebase/storage";
import { environment } from 'src/environments/environment';
import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'universal-late-swap',
  templateUrl: './universal-late-swap.component.html',
  styleUrls: ['./universal-late-swap.component.scss']
})
export class UniversalLateSwapComponent {
  @Input() jobData: any;

  storage: any;
  fullFilePath: string;
  fileURL: string;
  fileError: string;
  recordsAdded: any;

  fileUploadProgress: any;
  isFileAdded: boolean;
  isFileUploading: any;
  isDownloading: boolean;

  constructor(
    private jobsService: JobsService,
  ) { }

  ngOnInit(): void {
    this.storage = getStorage();
  }

  async readFileContent(file: File, fileType: any): Promise<void> {
    this.fileError = null;
    this.isFileAdded = true;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvData = e.target.result;
      const csvHeaders = this.extractCSVHeaders(csvData);
      console.log(csvHeaders);
      
      let isValid = true;

      if (fileType === 'ContestFile') {
        isValid = this.checkContestHeaders(csvHeaders);
      } else {
        isValid = this.checkEntriesHeaders(csvData);
      }

      if (!isValid) {
        const friendlyType = fileType === 'ContestFile' ? 'Contest File' : 'Entries File';
        this.fileError = `The file you added is not a valid ${friendlyType}`;
        return;
      }
      
      this.handleFileUpload(file, fileType);
    };
    reader.readAsText(file);
  }

  extractCSVHeaders(csvString: string): string[] {
    // Split the CSV string by line breaks and then split the first line by commas
    const lines = csvString.split('\n');
    const headers = lines[0].split(',').map(header => header.trim());
    return headers;
  }

  entriesHeaders = ['Entry ID', 'Contest Name', 'Contest ID', 'Entry Fee'];
  contestHeaders = ['Rank', 'EntryId', 'EntryName', 'TimeRemaining', 'Points', 'Lineup'];

  checkEntriesHeaders(csvHeaders: string[]): boolean {
    const matchingHeaders = this.entriesHeaders.filter(header => csvHeaders.includes(header));
    return matchingHeaders.length === this.entriesHeaders.length;
  }

  // Check if csvHeaders contain all required contestHeaders
  checkContestHeaders(csvHeaders: string[]): boolean {
    const matchingHeaders = this.contestHeaders.filter(header => csvHeaders.includes(header));
    return matchingHeaders.length === this.contestHeaders.length;
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  handleFileUpload(file: File, fileType: any): void {
    this.isFileUploading = true;

    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const dateString = `${month}_${year}`;
    const fileUUID = this.generateUUID();

    const filePath = `job-assets/${dateString}/${this.jobData.userId}_${fileUUID}_${fileType}`;
    const storageRef = ref(this.storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        this.fileUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.log('error with upload' + error)
        this.isFileUploading = false;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.fullFilePath = uploadTask.snapshot.ref.fullPath;
          this.fileURL = downloadURL;
          const fileInfo = {
            fileURL: downloadURL,
            fullPath: this.fullFilePath,
            fileUUID: fileUUID,
          };

          if (!this.jobData.lateSwapData) {
            this.jobData.lateSwapData = {};  // Initialize the object if it's null
          }

          if (fileType === 'ContestFile') {
            this.jobData.lateSwapData.contestFile = fileInfo;
          } else {
            this.jobData.lateSwapData.entriesFile = fileInfo;
          }
          this.jobsService.saveJobDataSnapshot(this.jobData);
          this.isFileUploading = false;
        })
          .catch((e) => {
            console.log(e);
          });
      }
    );
  }

  clearFile(type) {
    if (type === 'ContestFile') {
      this.jobData.lateSwapData.contestFile = null;
    } else {
      this.jobData.lateSwapData.entriesFile = null;
    }
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

}