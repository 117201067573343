import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service';
import { StateService } from 'src/app/services/state.service';

import { MLB_TEAM_LOGO_IDS } from 'src/app/constants/team-images/MLB/mlb-team-image-map';

@Component({
  selector: 'team-filter-bar',
  templateUrl: './team-filter-bar.component.html',
  styleUrls: ['./team-filter-bar.component.scss']
})
export class TeamFilterBarComponent {
  @ViewChild('barContainer', { static: false }) barContainer!: ElementRef;
  @Input() jobData: any;
  @Input() match: any;

  awayTeam: any = "";
  awayImgURL: any = "";
  homeTeam: any = "";
  homeImgURL: any = "";

  awaySelectedCount: number = 0
  homeSelectedCount: number = 0;

  constructor(
    private stateService: StateService,
    private jobsService: JobsService,
  ) { }

  // @HostListener('document:click', ['$event'])
  // onClickOutside(event: Event): void {
  //   if (!this.barContainer.nativeElement.contains(event.target)) {
  //     this.jobData.currentSelectedMatch = null;
  //   }
  // }

  ngOnInit() {
    this.generateData(this.match);
  }

  generateData(matchupString: any) {
    if (matchupString && matchupString.name) {
      const [teams, timePart, timeZone] = matchupString.name.split(' ');
      const [awayTeam, homeTeam] = teams.split('@');

      this.homeTeam = homeTeam;
      this.awayTeam = awayTeam;

      this.getSelectedCounts();

      let awayTeamId
      let homeTeamId

      switch (this.jobData.sport) {
        case 'MLB':
          awayTeamId = MLB_TEAM_LOGO_IDS[awayTeam] || 'default-id';
          homeTeamId = MLB_TEAM_LOGO_IDS[homeTeam] || 'default-id';
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${awayTeamId}.svg`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${homeTeamId}.svg`;
          break;
        case 'MMA':
          const awayFighter = this.jobData.draftGroup.find((p) => p.Name.includes(awayTeam));
          awayTeamId = awayFighter.Name || 'default-id';
          const homeFighter = this.jobData.draftGroup.find((p) => p.Name.includes(homeTeam));
          homeTeamId = homeFighter.Name
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/ufc-fighter-images/${awayTeamId}.jpg`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/ufc-fighter-images/${homeTeamId}.jpg`;
          break;
        case 'NFL':
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${awayTeam}.png`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${homeTeam}.png`;
          break;
        case 'NBA':
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/nba-team-logos/${awayTeam}.svg`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/nba-team-logos/${homeTeam}.svg`;
          break;
        default:
          console.log('no team img generation match found');
      }
    }
  }

  getSelectedCounts() {
    const players = this.jobData.draftGroup;
    const awaySelections = players.filter((p) => p.Team === this.awayTeam && p.selected);
    const homeSelections = players.filter((p) => p.Team === this.homeTeam && p.selected);
    this.awaySelectedCount = awaySelections.length;
    this.homeSelectedCount = homeSelections.length;
  }

  onClickSortByTeam(team) {
    this.jobData.currentSort = team;
    const sortedPlayers = this.jobData.draftGroup.sort((a, b) => {
      const aIsTeam = a.Team === team;
      const bIsTeam = b.Team === team;

      if (aIsTeam && !bIsTeam) {
        return -1;
      } else if (!aIsTeam && bIsTeam) {
        return 1;
      } else {
        return 0;
      }
    });
    this.jobData.draftGroup = sortedPlayers;
    this.stateService.setProjectionDraftGroup(sortedPlayers);
  }

  onClickUnCheckTeam(team) {
    const players = this.jobData.draftGroup;
    players.forEach((p) => {
      if (p.Team === team) {
        p.selected = false;
      }
    });

    this.jobData.draftGroup = players;
    this.stateService.setProjectionDraftGroup(players);
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.getSelectedCounts();
  }

  onClickCheckTeam(team) {
    const players = this.jobData.draftGroup;
    players.forEach((p) => {
      if (p.Team === team) {
        p.selected = true;
      }
    });

    this.jobData.draftGroup = players;
    this.stateService.setProjectionDraftGroup(players);
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.getSelectedCounts();
  }

  onClickUnCheckMatch() {
    const players = this.jobData.draftGroup;
    players.forEach((p) => {
      if (p.Team === this.awayTeam || p.Team === this.homeTeam) {
        p.selected = false;
      }
    });

    this.jobData.draftGroup = players;
    this.stateService.setProjectionDraftGroup(players);
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.getSelectedCounts();
  }

  onClickCheckMatch() {
    const players = this.jobData.draftGroup;
    players.forEach((p) => {
      if (p.Team === this.awayTeam || p.Team === this.homeTeam) {
        p.selected = true;
      }
    });

    this.jobData.draftGroup = players;
    this.stateService.setProjectionDraftGroup(players);
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.getSelectedCounts();
  }

  onClickCloseBar() {
    this.jobData.currentSelectedMatch = null;
  }

}
