import { BASE_ENTRY_TABLE_SIM_COLUMNS } from "./shared-config/shared-table-data";

export const NBA_LINEUP_GEN_TABLES_OPTO = {
  DK_CLASSIC: [
    { prop: 'pg', name: 'PG', tooltip: null, noId: true, filterValue: 'position pg' },
    { prop: 'sg', name: 'SG', tooltip: null, noId: true, filterValue: 'position sg' },
    { prop: 'sf', name: 'SF', tooltip: null, noId: true, filterValue: 'position sf' },
    { prop: 'pf', name: 'PF', tooltip: null, noId: true, filterValue: 'position pf' },
    { prop: 'c', name: 'C', tooltip: null, noId: true, filterValue: 'position c' },
    { prop: 'g', name: 'G', tooltip: null, noId: true, filterValue: 'position g' },
    { prop: 'f', name: 'F', tooltip: null, noId: true, filterValue: 'position f' },
    { prop: 'util', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
  FD_CLASSIC: [
    { prop: 'pg1', name: 'PG', tooltip: null, noFrontId: true, filterValue: 'position pg1' },
    { prop: 'pg2', name: 'PG', tooltip: null, noFrontId: true, filterValue: 'position pg2' },
    { prop: 'sg1', name: 'SG', tooltip: null, noFrontId: true, filterValue: 'position sg1' },
    { prop: 'sg2', name: 'SG', tooltip: null, noFrontId: true, filterValue: 'position sg2' },
    { prop: 'sf1', name: 'SF', tooltip: null, noFrontId: true, filterValue: 'position sf1' },
    { prop: 'sf2', name: 'SF', tooltip: null, noFrontId: true, filterValue: 'position sf2' },
    { prop: 'pf1', name: 'PF', tooltip: null, noFrontId: true, filterValue: 'position pf1' },
    { prop: 'pf2', name: 'PF', tooltip: null, noFrontId: true, filterValue: 'position pf2' },
    { prop: 'c', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
  DK_SHOWDOWN: [
    { prop: 'cpt', name: 'CPT', tooltip: null, noId: true, filterValue: 'position cpt' },
    { prop: 'flex1', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex1' },
    { prop: 'flex2', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex2' },
    { prop: 'flex3', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex3' },
    { prop: 'flex4', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex4' },
    { prop: 'flex5', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex5' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
  FD_SHOWDOWN: [
    { prop: 'mvp', name: 'MVP', tooltip: null, noFrontId: true, filterValue: 'position mvp' },
    { prop: 'flex1', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex1' },
    { prop: 'flex2', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex2' },
    { prop: 'flex3', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex3' },
    { prop: 'flex4', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex4' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
}

export const NBA_LINEUP_GEN_TABLES_SIM = {
  DK_CLASSIC: [
    { prop: 'pg', name: 'PG', tooltip: null, noId: true, filterValue: 'position pg' },
    { prop: 'sg', name: 'SG', tooltip: null, noId: true, filterValue: 'position sg' },
    { prop: 'sf', name: 'SF', tooltip: null, noId: true, filterValue: 'position sf' },
    { prop: 'pf', name: 'PF', tooltip: null, noId: true, filterValue: 'position pf' },
    { prop: 'c', name: 'C', tooltip: null, noId: true, filterValue: 'position c' },
    { prop: 'g', name: 'G', tooltip: null, noId: true, filterValue: 'position g' },
    { prop: 'f', name: 'F', tooltip: null, noId: true, filterValue: 'position f' },
    { prop: 'util', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position f' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  FD_CLASSIC: [
    { prop: 'pg1', name: 'PG', tooltip: null, noFrontId: true, filterValue: 'position pg1' },
    { prop: 'pg2', name: 'PG', tooltip: null, noFrontId: true, filterValue: 'position pg2' },
    { prop: 'sg1', name: 'SG', tooltip: null, noFrontId: true, filterValue: 'position sg1' },
    { prop: 'sg2', name: 'SG', tooltip: null, noFrontId: true, filterValue: 'position sg2' },
    { prop: 'sf1', name: 'SF', tooltip: null, noFrontId: true, filterValue: 'position sf1' },
    { prop: 'sf2', name: 'SF', tooltip: null, noFrontId: true, filterValue: 'position sf2' },
    { prop: 'pf1', name: 'PF', tooltip: null, noFrontId: true, filterValue: 'position pf1' },
    { prop: 'pf2', name: 'PF', tooltip: null, noFrontId: true, filterValue: 'position pf2' },
    { prop: 'c', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  DK_SHOWDOWN: [
    { prop: 'cpt', name: 'CPT', tooltip: null, noId: true, filterValue: 'position cpt' },
    { prop: 'util1', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util1' },
    { prop: 'util2', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util2' },
    { prop: 'util3', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util3' },
    { prop: 'util4', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util4' },
    { prop: 'util5', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util5' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  FD_SHOWDOWN: [
    { prop: 'mvp', name: 'MVP', tooltip: null, noFrontId: true, filterValue: 'position mvp' },
    { prop: 'star', name: 'STAR', tooltip: null, noFrontId: true, filterValue: 'position star' },
    { prop: 'pro', name: 'PRO', tooltip: null, noFrontId: true, filterValue: 'position pro' },
    { prop: 'util1', name: 'UTIL', tooltip: null, noFrontId: true, filterValue: 'position util1' },
    { prop: 'util2', name: 'UTIL', tooltip: null, noFrontId: true, filterValue: 'position util2' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
}


export const NBA_LATE_SWAP_TABLES = {
  DK_CLASSIC: [
    { prop: 'PG', name: 'PG', tooltip: null, noId: true },
    { prop: 'SG', name: 'SG', tooltip: null, noId: true },
    { prop: 'SF', name: 'SF', tooltip: null, noId: true },
    { prop: 'PF', name: 'PF', tooltip: null, noId: true },
    { prop: 'C', name: 'C', tooltip: null, noId: true },
    { prop: 'G', name: 'G', tooltip: null, noId: true },
    { prop: 'F', name: 'F', tooltip: null, noId: true },
    { prop: 'UTIL', name: 'UTIL', tooltip: null, noId: true },
    { prop: 'Entry ID', name: 'Entry ID', tooltip: 'ID of the entry', filterValue: 'Entry ID' },
    { prop: 'Top1 Probability', name: 'Top1 Probability', tooltip: 'Probability of being the top 1% lineup', filterValue: 'Top1 Probability' },
    { prop: 'Updated Projection', name: 'Updated Projection', tooltip: 'Updated projection of lineup performance', filterValue: 'Updated Projection' },
    { prop: 'Average ROI', name: 'Average ROI', tooltip: 'Average return on investment for the lineup', filterValue: 'Average ROI' },
    { prop: 'Salary', name: 'Salary', tooltip: 'Total salary of the lineup', filterValue: 'Salary' },
    { prop: 'Ownership Sum', name: 'Ownership Sum', tooltip: 'Sum of player ownership percentages in the lineup', filterValue: 'Ownership Sum' },
    { prop: 'Average Fpts', name: 'Average Fpts', tooltip: 'Average fantasy points of the lineup', filterValue: 'Average Fpts' },
    { prop: 'Portfolio ID', name: 'Portfolio ID', tooltip: 'ID of the portfolio the lineup belongs to', filterValue: 'Portfolio ID' },
    { prop: 'Minutes Remaining', name: 'Minutes Remaining', tooltip: 'Total minutes remaining for all players in the lineup', filterValue: 'Minutes Remaining' },
    { prop: 'Original Projection', name: 'Original Projection', tooltip: 'Original projection of lineup performance', filterValue: 'Original Projection' },
    { prop: 'Cash Probability', name: 'Cash Probability', tooltip: 'Probability of the lineup cashing in a contest', filterValue: 'Cash Probability' },
    { prop: 'Win Probability', name: 'Win Probability', tooltip: 'Probability of the lineup winning the contest', filterValue: 'Win Probability' },
    { prop: 'Primary Stack', name: 'Primary Stack', tooltip: 'Primary stack in the lineup (team and number of players)', filterValue: 'Primary Stack' },
    { prop: 'Secondary Stack', name: 'Secondary Stack', tooltip: 'Secondary stack in the lineup (team and number of players)', filterValue: 'Secondary Stack' },
    { prop: 'isOptimalPortfolio', name: 'Optimal Portfolio', tooltip: 'Indicates if the lineup is in the optimal portfolio', filterValue: 'isOptimalPortfolio' },
    { prop: 'Ownership Prod', name: 'Ownership Product', tooltip: 'Product of player ownership percentages in the lineup', filterValue: 'Ownership Prod' },
    { prop: 'Std Rank', name: 'Std Rank', tooltip: 'Standard rank of the lineup', filterValue: 'Std Rank' },
    { prop: 'Std Fpts', name: 'Std Fpts', tooltip: 'Standard fantasy points of the lineup', filterValue: 'Std Fpts' },
    { prop: 'Average Rank', name: 'Average Rank', tooltip: 'Average rank of the lineup in simulations', filterValue: 'Average Rank' },
  ],
}