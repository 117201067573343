import { NBA_LINEUP_FILTERS_SIM, NBA_LINEUP_FILTERS_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-filters/nba-lineup-filters";
import { NBA_LINEUP_GEN_TABLES_SIM, NBA_LINEUP_GEN_TABLES_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-tables/nba-lineup-generator-tables";

import { MLB_LINEUP_FILTERS_SIM, MLB_LINEUP_FILTERS_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-filters/mlb-lineup-filters";
import { MLB_LINEUP_GEN_TABLES_SIM, MLB_LINEUP_GEN_TABLES_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-tables/mlb-lineup-generator-tables";

import { PGA_LINEUP_FILTERS_SIM, PGA_LINEUP_FILTERS_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-filters/pga-lineup-filters";
import { PGA_LINEUP_GEN_TABLES_OPTO, PGA_LINEUP_GEN_TABLES_SIM } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-tables/pga-lineup-generator-tables";

import { MMA_LINEUP_FILTERS_OPTO, MMA_LINEUP_FILTERS_SIM } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-filters/mma-lineup-filters";
import { MMA_LINEUP_GEN_TABLES_OPTO, MMA_LINEUP_GEN_TABLES_SIM } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-tables/mma-lineup-generator-tables";

import { NFL_LINEUP_FILTERS_SIM, NFL_LINEUP_FILTERS_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-filters/nfl-lineup-filters";
import { NFL_LINEUP_GEN_TABLES_SIM, NFL_LINEUP_GEN_TABLES_OPTO } from "src/app/pages/portal/job-results/job-results-constants/lineup-generator-tables/nfl-lineup-generator-tables";


export const setLineupsTable = (sport, type, site, subType) => {

    const keyParts = `${sport}_${type}_${site}`;
    const key = keyParts.toLocaleLowerCase();
    switch (key) {
        case 'nba_opto_dk':
            if (subType === 'classic') {
                return NBA_LINEUP_GEN_TABLES_OPTO.DK_CLASSIC;
            } else {
                return NBA_LINEUP_GEN_TABLES_OPTO.DK_SHOWDOWN;
            }
        case 'nba_opto_fd':
            if (subType === 'classic') {
                return NBA_LINEUP_GEN_TABLES_OPTO.FD_CLASSIC;
            } else {
                return NBA_LINEUP_GEN_TABLES_OPTO.FD_SHOWDOWN;
            }
        case 'nba_sim_dk':
            if (subType === 'classic') {
                return NBA_LINEUP_GEN_TABLES_SIM.DK_CLASSIC;
            } else {
                return NBA_LINEUP_GEN_TABLES_SIM.DK_SHOWDOWN;
            }
        case 'nba_sim_fd':
            if (subType === 'classic') {
                return NBA_LINEUP_GEN_TABLES_SIM.FD_CLASSIC;
            } else {
                return NBA_LINEUP_GEN_TABLES_SIM.FD_SHOWDOWN;
            }

        case 'mlb_opto_dk':
            return MLB_LINEUP_GEN_TABLES_OPTO.DK_CLASSIC;
        case 'mlb_opto_fd':
            return MLB_LINEUP_GEN_TABLES_OPTO.FD_CLASSIC;
        case 'mlb_sim_dk':
            return MLB_LINEUP_GEN_TABLES_SIM.DK_CLASSIC;
        case 'mlb_sim_fd':
            return MLB_LINEUP_GEN_TABLES_SIM.FD_CLASSIC;

        case 'pga_opto_dk':
            return PGA_LINEUP_GEN_TABLES_OPTO.DK_CLASSIC;
        case 'pga_opto_fd':
            return PGA_LINEUP_GEN_TABLES_OPTO.FD_CLASSIC;
        case 'pga_sim_dk':
            return PGA_LINEUP_GEN_TABLES_SIM.DK_CLASSIC;
        case 'pga_sim_dk':
            return PGA_LINEUP_GEN_TABLES_SIM.FD_CLASSIC;

        case 'mma_opto_dk':
            return MMA_LINEUP_GEN_TABLES_OPTO.DK_CLASSIC;
        case 'mma_opto_fd':
            return MMA_LINEUP_GEN_TABLES_OPTO.FD_CLASSIC;
        case 'mma_sim_dk':
            return MMA_LINEUP_GEN_TABLES_SIM.DK_CLASSIC;
        case 'mma_sim_dk':
            return MMA_LINEUP_GEN_TABLES_SIM.FD_CLASSIC;

        case 'nfl_opto_dk':
            if (subType === 'classic') {
                return NFL_LINEUP_GEN_TABLES_OPTO.DK_CLASSIC;
            } else {
                return NFL_LINEUP_GEN_TABLES_OPTO.DK_SHOWDOWN;
            }
        case 'nfl_opto_fd':
            if (subType === 'classic') {
                return NFL_LINEUP_GEN_TABLES_OPTO.FD_CLASSIC;
            } else {
                return NFL_LINEUP_GEN_TABLES_OPTO.FD_SHOWDOWN;
            }
        case 'nfl_sim_dk':
            if (subType === 'classic') {
                return NFL_LINEUP_GEN_TABLES_SIM.DK_CLASSIC;
            } else {
                return NFL_LINEUP_GEN_TABLES_SIM.DK_SHOWDOWN;
            }
        case 'nfl_sim_fd':
            if (subType === 'classic') {
                return NFL_LINEUP_GEN_TABLES_SIM.FD_CLASSIC;
            } else {
                return NFL_LINEUP_GEN_TABLES_SIM.FD_SHOWDOWN;
            }
    }
}

export const setAvailableFilters = (sport, type, site) => {

    const keyParts = `${sport}_${type}_${site}`;
    const key = keyParts.toLocaleLowerCase();

    switch (key) {
        case 'nba_opto_dk':
        case 'nba_opto_fd':
            return NBA_LINEUP_FILTERS_OPTO;
        case 'nba_sim_dk':
        case 'nba_sim_fd':
            return NBA_LINEUP_FILTERS_SIM;
        case 'mlb_opto_dk':
        case 'mlb_opto_fd':
            return MLB_LINEUP_FILTERS_OPTO;
        case 'mlb_sim_dk':
        case 'mlb_sim_fd':
            return MLB_LINEUP_FILTERS_SIM;
        case 'pga_sim_dk':
        case 'pga_sim_fd':
            return PGA_LINEUP_FILTERS_SIM;
        case 'pga_opto_dk':
        case 'pga_opto_fd':
            return PGA_LINEUP_FILTERS_OPTO;
        case 'mma_sim_dk':
        case 'mma_sim_fd':
            return MMA_LINEUP_FILTERS_SIM;
        case 'mma_opto_dk':
        case 'mma_opto_fd':
            return MMA_LINEUP_FILTERS_OPTO;
        case 'nfl_sim_dk':
        case 'nfl_sim_fd':
            return NFL_LINEUP_FILTERS_SIM;
        case 'nfl_opto_dk':
        case 'nfl_opto_fd':
            return NFL_LINEUP_FILTERS_OPTO;
        // TODO: ADD CASES AND MAPPINGS
    }
}