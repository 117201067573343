import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getTeamColorNFL } from '../constants/team-colors/nfl-team-colors-dictionary';

@Pipe({
  name: 'tableTeamBadgePrefix'
})
export class TableTeamBadgePipePrefix implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(team: any): SafeHtml {
    const teamColor = getTeamColorNFL(team);
    if (team && team.length < 3) {
        team = '&nbsp;' + team + '&nbsp;';
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      `<span class="table-team-chip" style="border: 1px solid ${teamColor}">${team}</span>&nbsp;&nbsp;&nbsp;`
    );
  }
}