const positionConfigMap = {
    'MLB_classic_dk': 'P, P, C, 1B, 2B, 3B, SS, OF, OF, OF',
    'MLB_classic_fd': 'MVP, STAR, UTIL, UTIL, UTIL',
    'MLB_showdown_dk': 'CPT, UTIL, UTIL, UTIL, UTIL, UTIL',
    'MLB_showdown_fd': 'MVP, STAR, UTIL, UTIL, UTIL',
    'MLB_classic_ikb': 'P, P, UTIL, UTIL, UTIL, UTIL, UTIL, UTIL, UTIL',

    'PGA_classic_dk': 'G,G,G,G,G,G',
    'PGA_classic_fd': 'G,G,G,G,G,G',
    'PGA_showdown_dk': 'G,G,G,G,G,G',
    'PGA_showdown_fd': 'G,G,G,G,G,G',

    'MMA_classic_dk': 'F,F,F,F,F,F',
    'MMA_classic_fd': 'F,F,F,F,F,F',
    'MMA_showdown_dk': 'F,F,F,F,F,F',
    'MMA_showdown_fd': 'F,F,F,F,F,F',

    'NFL_classic_dk': 'QB,RB,RB,TE,WR,WR,WR,FLEX,DST',
    'NFL_classic_fd': 'QB,RB,RB,TE,WR,WR,WR,FLEX,DST', 
    'NFL_showdown_dk': 'CPT,FLEX,FLEX,FLEX,FLEX,FLEX', 
    'NFL_showdown_fd': 'MVP,FLEX,FLEX,FLEX,FLEX',

    'NBA_classic_dk': 'PG,SG,SF,PF,C,G,F,UTIL',
    'NBA_classic_fd': 'PG1,PG2,SG1,SG2,SF1,SF2,PF1,PF2,C',
    'NBA_showdown_dk': 'CPT,UTIL1,UTIL2,UTIL3,UTIL4,UTIL5',
    'NBA_showdown_fd': 'MVP,STAR,PRO,UTIL1,UTIL2'

}

function generateFullConfig(key, site) {
    const positions = positionConfigMap[key];
    if (!positions) return null;

    const positionsArray = positions.split(', ');
    let playersArray = null;
    if (site === 'dk') {
        playersArray = positionsArray.map(pos => `Player Name (playerID)`);
    } else {
        playersArray = positionsArray.map(pos => `PlayerId:Player Name`);
    }

    return `${positions} \n ${playersArray.join(', ')}`;
}

async function setJobLineupHeaders(sport, site, subtype, onlyPositions) {
    // Generate the key for configuration retrieval
    const key = `${sport}_${subtype}_${site}`;

    let headerConfig = generateFullConfig(key, site);
    if (onlyPositions) {
        headerConfig = positionConfigMap[key];
    }

    if (!headerConfig) {
        throw new Error('Configuration to set table not found for the given sport, site, and type');
    }

    return headerConfig;
}

export default setJobLineupHeaders;
