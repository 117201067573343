const convertArrayToCSV = (array: any[]): string => {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((obj) => Object.values(obj).join(',') + '\n');
    return header + rows.join('');
}

export const generateExportFile = (sport, jobData, lineups, download) => {
    switch (sport) {
        case 'NBA':
            nbaFile(jobData, lineups, download);
            break;
        case 'MLB':
            mlbFile(jobData, lineups, download);
            break;
        case 'MMA':
            mmaFile(jobData, lineups, download);
            break;
        case 'TEN':
            tenFile(jobData, lineups, download);
            break;
        case 'PGA':
            pgaFile(jobData, lineups, download);
            break;
        case 'NFL':
            nflFile(jobData, lineups, download);
            break;
        case 'NAS':
            nascarFile(jobData, lineups, download);
            break;
    }
}

// NBA
const nbaFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    const jobTypeMappings = {
        'classic': {
            'dk': ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL'],
            'fd': ['PG1', 'PG2', 'SG1', 'SG2', 'SF1', 'SF2', 'PF1', 'PF2', 'C']
        },
        'showdown': {
            'dk': ['CPT', 'UTIL1', 'UTIL2', 'UTIL3', 'UTIL4', 'UTIL5'],
            'fd': ['MVP', 'STAR', 'PRO', 'UTIL1', 'UTIL2']
        }
    };

    const positions = jobTypeMappings[jobData.jobSubType]?.[jobData.site];
    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

// MLB
const mlbFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    let positions = [];
    const jobTypeMappingsOpto = {
        'classic': {
            'dk': ['P1', 'P2', 'C1B', '2B', '3B', 'SS', 'OF1', 'OF2', 'OF3', 'UTIL'],
            'fd': ['P', 'C1B', '2B', '3B', 'SS', 'OF1', 'OF2', 'OF3', 'UTIL'],
        },
        'showdown': {
            'dk': ['CPT', 'UTIL', 'UTIL2', 'UTIL3', 'UTIL4', 'UTIL5'],
            'fd': ['MVP', 'STAR', 'UTIL', 'UTIL2', 'UTIL3']
        }
    };
    const jobTypeMappingsSim = {
        'classic': {
            'dk': ['P1', 'P2', 'C', '1B', '2B', '3B', 'SS', 'OF1', 'OF2', 'OF3'],
            'fd': ['P', 'C1B', '2B', '3B', 'SS', 'OF1', 'OF2', 'OF3', 'UTIL'],
        },
        'showdown': {
            'dk': ['CPT', 'UTIL', 'UTIL2', 'UTIL3', 'UTIL4', 'UTIL5'],
            'fd': ['MVP', 'STAR', 'UTIL', 'UTIL2', 'UTIL3']
        }
    };

    if (jobData.jobType === 'simulator') {
        positions = jobTypeMappingsSim[jobData.jobSubType]?.[jobData.site];
    } else {
        positions = jobTypeMappingsOpto[jobData.jobSubType]?.[jobData.site];
    }

    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

const mmaFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    let positions = [];
    const jobTypeMappingsOpto = {
        'classic': {
            'dk': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'],
            'fd': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'],
        },
        'showdown': {
            'dk': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'], // need mapping
            'fd': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'], // need mapping
        }
    };
    const jobTypeMappingsSim = {
        'classic': {
            'dk': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'],
            'fd': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'],
        },
        'showdown': {
            'dk': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'], // need mapping
            'fd': ['F1', 'F2', 'F3', 'F4', 'F5', 'F6'], // need mapping
        }
    };

    if (jobData.jobType === 'simulator') {
        positions = jobTypeMappingsSim[jobData.jobSubType]?.[jobData.site];
    } else {
        positions = jobTypeMappingsOpto[jobData.jobSubType]?.[jobData.site];
    }

    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

const nascarFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    let positions = [];
    const jobTypeMappingsOpto = {
        'classic': {
            'dk': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'],
            'fd': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'],
        },
        'showdown': {
            'dk': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'], // need mapping
            'fd': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'], // need mapping
        }
    };
    const jobTypeMappingsSim = {
        'classic': {
            'dk': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'],
            'fd': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'],
        },
        'showdown': {
            'dk': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'], // need mapping
            'fd': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'], // need mapping
        }
    };

    if (jobData.jobType === 'simulator') {
        positions = jobTypeMappingsSim[jobData.jobSubType]?.[jobData.site];
    } else {
        positions = jobTypeMappingsOpto[jobData.jobSubType]?.[jobData.site];
    }

    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

const tenFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    let positions = [];
    const jobTypeMappingsOpto = {
        'classic': {
            'dk': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
            'fd': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
        },
        'showdown': {
            'dk': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
            'fd': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
        }
    };
    const jobTypeMappingsSim = {
        'classic': {
            'dk': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
            'fd': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
        },
        'showdown': {
            'dk': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
            'fd': ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
        }
    };

    if (jobData.jobType === 'simulator') {
        positions = jobTypeMappingsSim[jobData.jobSubType]?.[jobData.site];
    } else {
        positions = jobTypeMappingsOpto[jobData.jobSubType]?.[jobData.site];
    }

    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

const pgaFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    let positions = [];
    const jobTypeMappingsOpto = {
        'classic': {
            'dk': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'],
            'fd': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'],
        },
        'showdown': {
            'dk': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'], // need mapping
            'fd': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'], // need mapping
        }
    };
    const jobTypeMappingsSim = {
        'classic': {
            'dk': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'],
            'fd': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'],
        },
        'showdown': {
            'dk': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'], // need mapping
            'fd': ['G1', 'G2', 'G3', 'G4', 'G5', 'G6'], // need mapping
        }
    };

    if (jobData.jobType === 'simulator') {
        positions = jobTypeMappingsSim[jobData.jobSubType]?.[jobData.site];
    } else {
        positions = jobTypeMappingsOpto[jobData.jobSubType]?.[jobData.site];
    }

    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

const nflFile = (jobData, lineups, download) => {
    const mapLineupData = (data, positions) => {
        return lineups.map(lineup => {
            const lineupData = {};
            positions.forEach(pos => lineupData[pos] = lineup[pos.toLowerCase()]);
            return lineupData;
        });
    };

    let fileData = [];
    let positions = [];
    const jobTypeMappingsOpto = {
        'classic': {
            'dk': ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'FLEX', 'DST'],
            'fd': ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'FLEX', 'DST'],
        },
        'showdown': {
            'dk': ['CPT', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5'],
            'fd': ['MVP', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4'],
        }
    };
    const jobTypeMappingsSim = {
        'classic': {
            'dk': ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'FLEX', 'DST'],
            'fd': ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'FLEX', 'DST'],
        },
        'showdown': {
            'dk': ['CPT', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5'],
            'fd': ['MVP', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4'],
        }
    };

    if (jobData.jobType === 'simulator') {
        positions = jobTypeMappingsSim[jobData.jobSubType]?.[jobData.site];
    } else {
        positions = jobTypeMappingsOpto[jobData.jobSubType]?.[jobData.site];
    }

    if (positions) {
        fileData = mapLineupData(lineups, positions);
    }

    const csv = convertArrayToCSV(fileData);
    if (download) {
        downloadCSV(csv, `Refined_Lineups: ${jobData.slate}.csv`);
    } else {
        copyDataToClipboard(csv);
    }
}

const copyDataToClipboard = (data) => {
    setTimeout(() => {
        const textarea = document.createElement('textarea');
        textarea.value = data;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setTimeout(() => {
        }, 700);
    }, 1000);
};

const downloadCSV = (csv: string, filename: string) => {
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
}