import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { cloneDeep } from 'lodash';
import { setPositionFilters } from 'src/app/pages/portal/job-creator/primary-components/draft-group-projections/utilities/set-position-filters';

@Component({
  selector: 'side-panel-players',
  templateUrl: './side-panel-players.component.html',
  styleUrls: ['./side-panel-players.component.scss']
})
export class SidePanelPlayersComponent {
  @Input() jobData: any;
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;


  isSortDesc: boolean = true;
  isSearchActive: boolean;
  parentFilters: any = [];

  playersWithFilters: any = [];
  playerFiltersApplied: number = 0;
  existingPlayerFilters: any;
  showApplyFilters: boolean;
  showExistingFilters: boolean;
  totalExposureFilters: number = 0;
  filteredOutPlayers: any;
  isLoadingLatestValues: boolean;
  isLoadingPlayerData: boolean = true;
  awaitFilterChange: boolean;

  positionFilters: any = [];

  private subscription: Subscription;

  constructor(
    private stateService: StateService,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.subscription = this.stateService.entryBuilderFilters$.subscribe(
      value => {
        if (value.length) {
          this.parentFilters = value;
          setTimeout(() => {
            this.handleExistingPlayerFilters();
          },50);
        }
        if (this.awaitFilterChange) {
          this.awaitFilterChange = false;
          this.parentFilters = value;
          setTimeout(() => {
            this.handleExistingPlayerFilters();
          },50);
        }
      }
    );
    this.createPositions()
  }

  createPositions() {
    const key = `${this.jobData.sport.toLowerCase()}-${this.jobData.jobType}-${this.jobData.site}`;
    this.positionFilters = setPositionFilters(key);
  }

  ngOnDestroy() {
    // Clear the state in your service
    this.stateService.clearPlayerExposureFilters();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.isLoadingPlayerData = false;
    }, 600);
  }

  resetSearch() {
    this.isSearchActive = false;
    this.searchInput.nativeElement.value = '';
  }

  onClickHighestToLowest() {
    this.resetSearch();
    const sortedPlayers = this.jobData.currentPlayers.sort((a, b) => b.exposure - a.exposure);
    this.jobData.currentPlayers = sortedPlayers;
    this.isSortDesc = true;
    this.jobData.currentSort = null;
  }

  onClickLowestToHighest() {
    this.resetSearch();
    const sortedPlayers = this.jobData.currentPlayers.sort((a, b) => a.exposure - b.exposure);
    this.jobData.currentPlayers = sortedPlayers;
    this.isSortDesc = false;
    this.jobData.currentSort = null;
  }

  onClickPosition(position) {
    if (position === this.jobData.currentSort) {
      this.onClickHighestToLowest();
      return;
    }
    this.jobData.currentSort = position;
    const sortedPlayers = this.jobData.currentPlayers.sort((a, b) => {
      const aIncludesPos = a.Position.includes(position);
      const bIncludesPos = b.Position.includes(position);

      if (aIncludesPos && !bIncludesPos) {
        return -1;
      } else if (!aIncludesPos && bIncludesPos) {
        return 1;
      } else {
        return 0;
      }
    });

    this.jobData.currentPlayers = sortedPlayers;
  }

  onSearchPlayer(event) {
    this.jobData.currentSort = null;
    const query = event.target.value;
    this.isSearchActive = true;

    if (query.length === 0) {
      this.onClickHighestToLowest();
      return;
    }

    this.jobData.currentPlayers.sort((a, b) => {
      const similarityA = this.calculateFuzzyMatchScore(a.name, query);
      const similarityB = this.calculateFuzzyMatchScore(b.name, query);

      return similarityB - similarityA;
    });
  };

  calculateFuzzyMatchScore(str1, str2) {

    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    if (str1.includes(str2)) {
      return str2.length / str1.length;
    }
    return 0;
  };

  get filteredPlayers() {
    return this.jobData?.currentPlayers?.filter(player => player.name === this.jobData?.selectedExposurePlayer) || [];
  }

  onClickSortLineupsByPlayer(name) {
    if (this.jobData.playerHighlight) {
      const defaultSortedPlayers = this.jobData.currentLineups.sort((a, b) => b.fpts - a.fpts)
      this.jobData.currentLineups = defaultSortedPlayers;
      this.jobData.playerHighlight = null;
    } else {
      this.jobData.playerHighlight = name.trim();
      this.jobData.currentLineups = this.sortPlayerDataByPlayerName(name);
    }
  }

  containsPlayer(obj, playerName): boolean {
    // Extract player-related keys dynamically
    const playerKeys = Object.keys(obj).filter(key => typeof obj[key] === 'string' && key !== 'stack');

    return playerKeys.some(key => (obj[key] as string).includes(playerName));
  }

  sortPlayerDataByPlayerName(playerName) {
    return this.jobData.currentLineups.sort((a, b) => {
      const aContainsPlayer = this.containsPlayer(a, playerName);
      const bContainsPlayer = this.containsPlayer(b, playerName);
      // Sort such that objects containing the player come first
      if (aContainsPlayer && !bContainsPlayer) {
        return -1;
      } else if (!aContainsPlayer && bContainsPlayer) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onClickClearFocus() {
    this.jobData.selectedExposurePlayer = null;
  }

  onClickLockInPlayer(player) {
    if (player.minExposure == 100) {
      player.minExposure = null;
      player.maxExposure = null;
    } else {
      player.minExposure = 100;
      player.maxExposure = null;
    }
    this.handlePlayerChange();
  }

  onClickRemovePlayer(player) {
    if (player.maxExposure == 0) {
      player.minExposure = null;
      player.maxExposure = null;
    } else {
      player.minExposure = null;
      player.maxExposure = 0;
    }
    this.handlePlayerChange();
  }

  handlePlayerChange() {
    const currentPlayersState = cloneDeep(this.jobData.currentPlayers);
    const playerWithFilterValues = currentPlayersState.filter((p) => p.maxExposure !== null || p.minExposure !== null);
    this.playersWithFilters = playerWithFilterValues;
    if (this.playersWithFilters.length > 0) {
      this.showApplyFilters = true;
    } else {
      this.showApplyFilters = false;
    }
  }

  async onClickApplyFilters() {
    this.showExistingFilters = false;
    this.isLoadingLatestValues = true;
    this.playerFiltersApplied = this.playersWithFilters.length;
    const playerFilters = await this.generatePlayerFilters();
    const filterMessage = {
      isReset: false,
      playerFilters: playerFilters,
    }
    this.stateService.setPlayerExposureFilters(filterMessage);
    this.showApplyFilters = false;
  }

  onClickResetExposureFilters() {
    this.isLoadingLatestValues = true;
    this.awaitFilterChange = true;
    this.playerFiltersApplied = 0;
    this.playersWithFilters = null;
    this.zone.run(() => {});
    const filterMessage = {
      isReset: true,
      playerFilters: [],
    }
    this.stateService.setPlayerExposureFilters(filterMessage);
  }

  async generatePlayerFilters() {
    const filterSet = [];
    this.playersWithFilters.forEach((player) => {
      const playerNameValue = this.jobData.site === 'dk' ? `${player.name} (${player.playerId})` : `${player.playerId}:${player.name}`
      if (player.maxExposure !== null) {
        const maxFilter = {
          filterType: 'playerExposure',
          filterValue: playerNameValue,
          operator: 'less-than-or-equal-to',
          value: player.maxExposure,
        }
        filterSet.push(maxFilter);
      }
      if (player.minExposure !== null) {
        const minFilter = {
          filterType: 'playerExposure',
          filterValue: playerNameValue,
          operator: 'greater-than-or-equal-to',
          value: player.minExposure,
        }
        filterSet.push(minFilter);
      }
    });
    return filterSet;
  }

  handleExistingPlayerFilters() {
    this.filteredOutPlayers = [];
    const existingExposureFilters = this.parentFilters.filter((f) => f.filterType === 'playerExposure');
    this.existingPlayerFilters = existingExposureFilters;
    this.totalExposureFilters = existingExposureFilters.length;
    const currentPlayers = this.jobData.currentPlayers;
  
    existingExposureFilters.forEach((f) => {
      const playerNameValue = f.filterValue;
      let match = null;
      let playerId = '';
  
      if (this.jobData.site === 'dk') {
        match = playerNameValue.match(/([^()]+)\((\d+)\)/); // Matches "PlayerName(123456)"
      } else {
        match = playerNameValue.match(/([\d-]+):(.+)/); // Matches "123456:PlayerName"
      }
  
      if (match) {
        if (this.jobData.site === 'dk') {
          playerId = match[2].trim(); // Assuming the ID is in the second capturing group
        } else {
          playerId = match[1].trim(); // Assuming the ID is in the first capturing group
        }
  
        const matchingPlayer = currentPlayers.find((p) => p.playerId === playerId);
        if (matchingPlayer) {
          if (f.operator === 'greater-than-or-equal-to') {
            matchingPlayer.minExposure = f.value;
          } else {
            matchingPlayer.maxExposure = f.value;
          }
        } else {
          const object = {
            playerId: f.id,
            nameValue: f.filterValue,
            operator: f.operator,
            value: f.value,
          };
          this.filteredOutPlayers.push(object);
        }
      }
    });
  
    this.jobData.currentPlayers = currentPlayers;
    this.zone.run(() => {});
    this.isLoadingLatestValues = false;
  }
  

  onRemoveExistingFilter(filter) {
    this.isLoadingLatestValues = true;
    const reducedPlayerFilters = this.existingPlayerFilters.filter((f) => f.filterValue !== filter.filterValue && f.operator !== filter.operator);
    const isEmptyArray = reducedPlayerFilters.length == 0;
    if (isEmptyArray) {
      this.awaitFilterChange = true;
    }
    this.zone.run(() => {});
    const filterMessage = {
      isReset: isEmptyArray,
      playerFilters: reducedPlayerFilters,
    }
    this.stateService.setPlayerExposureFilters(filterMessage);
  }

}
