import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  orderBy,
  limit,
  addDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  getDocsFromServer,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LineupsService {

  database = getFirestore();

  constructor(
    private http: HttpClient,
  ) { }

  async createLineupsJob(userId, jobData,) {
    const timestamp = serverTimestamp();
    const seedData = {
      ...jobData,
      status: 'genesis',
      createdAt: timestamp,
      updatedAt: timestamp,
    }
    const docRef = await addDoc(collection(this.database, "user-profiles", userId, 'lineups-jobs'), seedData);
    return docRef.id;
  };

  async getLineupsJob(userId, jobId) {
    const docRef = await doc(this.database, "user-profiles", userId, 'lineups-jobs', jobId);
    const docSnap = await getDoc(docRef);
    const jobData = docSnap.data();
    return { id: docSnap.id, jobData: jobData };
  };

  async updateLineupsJob(userId, jobId, jobData) {
    const timestamp = serverTimestamp();
    const jobUpdate = {
      ...jobData,
      updatedAt: timestamp,
    }
    const docRef = await doc(this.database, "user-profiles", userId, 'lineups-jobs', jobId);
    await updateDoc(docRef, jobUpdate);
    const updatedDocSnapshot = await getDoc(docRef);
    return updatedDocSnapshot.data();
  }

  async getAllLineupsJobsByUserId(userId) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'lineups-jobs');
    const thirtyDaysAgo = Timestamp.fromMillis(Date.now() - 30 * 24 * 60 * 60 * 1000);

    const q = query(
      collectionRef,
      where('updatedAt', '>=', thirtyDaysAgo),
      orderBy('updatedAt', 'desc')
    );

    const querySnapshot = await getDocs(q);
    const recentJobs = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return data;
    });
    return recentJobs;
  }

  // TABLE SERVICE FUNCTIONS

  createBqTable(jobData) {
    const payload = {
      action: 'create',
      sport: jobData.sport,
      jobType: jobData.jobType === 'simulator' ? 'sim' : 'opto',
      jobSubType: jobData.jobSubType,
      site: jobData.site,
      csvId: `${jobData.userId}_${jobData.jobId}`,
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${environment.apiURL}/lineup_builder_controller`, payload, { headers });
  };

  queryBqTable(jobData, filters, numLineups, numUniques) {
    const payload = {
      action: 'query',
      bigQueryTableId: jobData.tableId,
      filters: filters,
      numLineups: numLineups,
      numUniques: numUniques,
      sport: jobData.sport,
      jobType: jobData.jobType === 'simulator' ? 'sim' : 'opto',
      jobSubType: jobData.jobSubType,
      site: jobData.site,
      csvId: jobData.csvId,
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${environment.apiURL}/lineup_builder_controller`, payload, { headers });
  }

}
