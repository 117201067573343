<!-- <div class="filter-work-in-progress">
  We are fine tuning team exposure and stack filtering accuracy. This will be back up soon.
</div> -->

<div
  class="team-exposure-tools-header"
  *ngIf="jobData && jobData?.currentTeams && !isLoadingLatestValues"
>
  <div class="top-row">
    <div class="team-count">Teams: {{ jobData?.currentTeams.length }}</div>
    <div class="sort-controls">
      <div
        class="sort-btn"
        [ngClass]="{ active: isSortDesc && !isSearchActive }"
        (click)="onClickHighestToLowest()"
      >
        <svg-icon
          [svgStyle]="{ 'height.px': 6 }"
          src="/assets/icons/chev-down.svg"
          style="transform: translateY(-2px)"
        ></svg-icon>
      </div>
      <div
        class="sort-btn"
        [ngClass]="{ active: !isSortDesc && !isSearchActive }"
        (click)="onClickLowestToHighest()"
      >
        <svg-icon
          [svgStyle]="{ 'height.px': 6 }"
          src="/assets/icons/chev-down.svg"
          style="transform: rotate(180deg) translateY(-3px)"
        ></svg-icon>
      </div>
    </div>
  </div>
  <div class="search-bar">
    <input
      #searchInput
      class="ss-input expanded"
      type="text"
      placeholder="Search teams..."
      (input)="onSearchTeam($event)"
    />
  </div>

  <div class="apply-filters-row">
    <div class="right">
      <button
        class="ss-secondary-btn reset-filters-btn"
        *ngIf="totalExposureFilters > 0"
        (click)="onClickResetExposureFilters()"
      >
        Reset
      </button>
      <button
        class="ss-secondary-btn apply-filter-btn"
        [disabled]="!showApplyFilters"
        [ngClass]="{ disabled: !showApplyFilters }"
        (click)="onClickApplyFilters()"
      >
        <svg-icon
          [svgStyle]="{ 'height.px': 14 }"
          src="/assets/icons/filter-solo.svg"
        ></svg-icon>
        Apply Exposure Filters
      </button>
    </div>
  </div>

  <div class="exposure-type-toggle">
    <div
      class="toggle-item active"
      (click)="onToggleStackList()"
      [class.active]="isPrimaryStack"
    >
      Primary Stack
    </div>
    <div
      class="toggle-item"
      (click)="onToggleStackList()"
      [class.active]="!isPrimaryStack"
    >
      Secondary Stack
    </div>
  </div>
</div>

<div
  class="team-exposure-wrapper"
  *ngIf="jobData && jobData?.currentTeams && !isLoadingLatestValues"
>

  <div class="team-item" *ngFor="let t of jobData.currentTeams">
    <div class="info-row">
      <div class="left">
        <div class="team-icon">
          <img [src]="getTeamImg(t.name)" alt="team-logo" />
        </div>
        <div class="team-detail">
          {{ t.name }}
        </div>
      </div>
      <div class="right">
        <div
          class="action-btn"
          (click)="onClickLockInTeam(t)"
          [ngClass]="{ 'active-green': (isPrimaryStack && t.minPrimaryExposure == 100) || (!isPrimaryStack && t.minSecondaryExposure == 100) }"
        >
          <svg-icon
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/lock.svg"
          ></svg-icon>
        </div>

        <div
          class="action-btn"
          (click)="onClickRemoveTeam(t)"
          [ngClass]="{ 'active-red': (isPrimaryStack && t.maxPrimaryExposure == 0 ) || (!isPrimaryStack && t.maxSecondaryExposure == 0)}"
        >
          <svg-icon
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/trash-solid.svg"
          ></svg-icon>
        </div>

        <div
          class="action-btn"
          (click)="onClickSortLineupsByTeam(t.name)"
          [ngClass]="{ 'active-sort': t.name === jobData.teamHighlightName }"
        >
          <svg-icon
            class="utility-search-icon"
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/featured-sort.svg"
          ></svg-icon>
        </div>
      </div>
    </div>

    <div class="tools-row">
      <div class="min-label">Min Exp</div>
      <input
        *ngIf="isPrimaryStack"
        class="exp-input"
        type="number"
        placeholder="Filter Val"
        (input)="handleTeamChange()"
        [(ngModel)]="t.minPrimaryExposure"
        [ngClass]="{ 'active-green': (isPrimaryStack && t.minPrimaryExposure == 100) || (!isPrimaryStack && t.minSecondaryExposure == 100) }"
      />
      <input
        *ngIf="!isPrimaryStack"
        class="exp-input"
        type="number"
        placeholder="Filter Val"
        (input)="handleTeamChange()"
        [(ngModel)]="t.minSecondaryExposure"
        [ngClass]="{ 'active-green': (isPrimaryStack && t.minPrimaryExposure == 100) || (!isPrimaryStack && t.minSecondaryExposure == 100) }"
      />
      <div class="current-exp">
        {{
          isPrimaryStack
            ? t.primaryGlobalExposurePct
            : t.secondaryGlobalExposurePct
        }}%
      </div>
      <input
        *ngIf="isPrimaryStack"
        class="exp-input"
        type="number"
        placeholder="Filter Val"
        (input)="handleTeamChange()"
        [(ngModel)]="t.maxPrimaryExposure"
        [ngClass]="{ 'active-red': (isPrimaryStack && t.maxPrimaryExposure == 100) || (!isPrimaryStack && t.maxSecondaryExposure == 100) }"
      />
      <input
        *ngIf="!isPrimaryStack"
        class="exp-input"
        type="number"
        placeholder="Filter Val"
        (input)="handleTeamChange()"
        [(ngModel)]="t.maxSecondaryExposure"
        [ngClass]="{ 'active-red': (isPrimaryStack && t.maxPrimaryExposure == 100) || (!isPrimaryStack && t.maxSecondaryExposure == 100) }"
      />
      <div class="max-label">Max Exp</div>
    </div>

    <div
      class="stack-type-summary-row"
      *ngIf="selectedStackLengthFiltersTeamName !== t.name"
    >
      <div
        class="stack-type-widget"
        *ngFor="let stack of ['two', 'three', 'four', 'five']; let i = index"
      >
        <div class="stack-type-label">{{ i + 2 }} STK</div>
        <div
          class="stack-type-value"
          [ngClass]="{
            placeholder:
              (isPrimaryStack &&
                t.primaryStackExposure[stack + 'Stack'] == 0) ||
              (!isPrimaryStack &&
                t.secondaryStackExposure[stack + 'Stack'] == 0)
          }"
        >
          {{
            isPrimaryStack
              ? t.primaryStackExposure[stack + 'Stack']
              : t.secondaryStackExposure[stack + 'Stack']
          }}
        </div>
      </div>
    </div>

    <div *ngIf="selectedStackLengthFiltersTeamName === t.name && isPrimaryStack">
      <div
        class="stack-filter-row"
        *ngFor="let filter of t.primaryStackLengths"
      >
        <div class="info-row">
          <div class="left">
            <div class="team-detail">{{ t.name }}: {{ filter.name }}</div>
          </div>
          <div class="right">
            <div
              class="action-btn"
              (click)="onClickSortLineupsByStackLengthType(t.name, filter.name)"
              [ngClass]="{
                'active-sort':
                  t.name + ' ' + filter.name ===
                  jobData.stackLengthHighlightTeam
              }"
            >
              <svg-icon
                class="utility-search-icon"
                [svgStyle]="{ 'height.px': 13 }"
                src="/assets/icons/featured-sort.svg"
              ></svg-icon>
            </div>
            <div class="action-btn" (click)="collapseStackLengthFilters()">
              <svg-icon
                [svgStyle]="{ 'height.px': 9 }"
                src="/assets/icons/close-solo.svg"
              ></svg-icon>
            </div>
          </div>
        </div>
        <div class="tools-row">
          <div class="min-label">Min</div>
          <input
            class="exp-input"
            type="number"
            placeholder="Filter Val"
            (input)="onChangeStackLengthFilter()"
            [(ngModel)]="filter.min"
          />
          <div class="current-exp">
            {{ filter.currentValue }}
          </div>
          <input
            class="exp-input"
            type="number"
            placeholder="Filter Val"
            (input)="onChangeStackLengthFilter()"
            [(ngModel)]="filter.max"
          />
          <div class="max-label">Max</div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedStackLengthFiltersTeamName === t.name && !isPrimaryStack">
      <div
        class="stack-filter-row"
        *ngFor="let filter of t.secondaryStackLengths"
      >
        <div class="info-row">
          <div class="left">
            <div class="team-detail">{{ t.name }}: {{ filter.name }}</div>
          </div>
          <div class="right">
            <div
              class="action-btn"
              (click)="onClickSortLineupsByStackLengthType(t.name, filter.name)"
              [ngClass]="{
                'active-sort':
                  t.name + ' ' + filter.name ===
                  jobData.stackLengthHighlightTeam
              }"
            >
              <svg-icon
                class="utility-search-icon"
                [svgStyle]="{ 'height.px': 13 }"
                src="/assets/icons/featured-sort.svg"
              ></svg-icon>
            </div>
            <div class="action-btn" (click)="collapseStackLengthFilters()">
              <svg-icon
                [svgStyle]="{ 'height.px': 9 }"
                src="/assets/icons/close-solo.svg"
              ></svg-icon>
            </div>
          </div>
        </div>
        <div class="tools-row">
          <div class="min-label">Min</div>
          <input
            class="exp-input"
            type="number"
            placeholder="Filter Val"
            (input)="onChangeStackLengthFilter()"
            [(ngModel)]="filter.min"
          />
          <div class="current-exp">
            {{ filter.currentValue }}
          </div>
          <input
            class="exp-input"
            type="number"
            placeholder="Filter Val"
            (input)="onChangeStackLengthFilter()"
            [(ngModel)]="filter.max"
          />
          <div class="max-label">Max</div>
        </div>
      </div>
    </div>

  </div>
</div>
