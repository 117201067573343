import { Component, Input } from '@angular/core';
import { getStorage, ref, getDownloadURL, updateMetadata, uploadBytesResumable } from "firebase/storage";
import { NBA_LATE_SWAP_TABLES } from '../../job-results-constants/lineup-generator-tables/nba-lineup-generator-tables';
import { NFL_LATE_SWAP_TABLES } from '../../job-results-constants/lineup-generator-tables/nfl-lineup-generator-tables';

@Component({
  selector: 'job-results-late-swap-data',
  templateUrl: './job-results-late-swap-data.component.html',
  styleUrls: ['./job-results-late-swap-data.component.scss']
})
export class JobResultsLateSwapDataComponent {
  @Input() jobData: any;
  activeSection: string = 'p1';
  columns: any;
  rows: any = [];

  // From old vet.
  storage: any;
  lateSwapPortfolios: any;
  selectedPortfolio: any;
  totalPortfolios: number = 0;

  lateSwapHeader: any = 'Late Swap Portfolio 1';

  isCopyingCSV: boolean = false;
  showDownloadOptions: boolean = false;
  isDownloading: boolean = false;

  lineupRows: any = [];
  lineupColumns = [];

  ngOnInit() {
    this.storage = getStorage();
    this.columns = this.setLateSwapColumns();
    console.log(this.jobData.lateSwapResults);
    this.handleLateSwapData();
  }

  handleLateSwapData() {
    const numberOfPortfolios = this.jobData.lateSwapResults.length || 0;
    if (numberOfPortfolios > 0) {
      this.totalPortfolios = numberOfPortfolios;
      this.jobData.lateSwapResults.sort((a, b) => {
        return b.isOptimalPortfolio - a.isOptimalPortfolio;
      });
      this.setTableData('0');
    }
  }

  setLateSwapColumns() {
    if (this.jobData.sport === 'NFL') {
      return NFL_LATE_SWAP_TABLES.DK_CLASSIC;
    } else {
      return NBA_LATE_SWAP_TABLES.DK_CLASSIC;
    }
  }

  onToggleNav(portfolio) {
    this.setTableData(portfolio);
  }

  async setTableData(portfolio) {
    const portfolioDisplayNumber = this.setDisplayNumber(portfolio);
    this.lateSwapHeader = `Late Swap Portfolio ${portfolioDisplayNumber}`;
    this.activeSection = portfolio;
    const portfolioNumber = parseInt(portfolio);
    this.selectedPortfolio = this.jobData.lateSwapResults[portfolioNumber];
    const portfolioData = this.jobData.lateSwapResults[portfolioNumber].portfolioLineups || null;
    if (portfolioData) {
      if (this.jobData.sport === 'NFL') {
        const treatedData = await this.cleanDataForNFL(portfolioData);
        this.rows = treatedData;
      } else {
        this.rows = portfolioData;
      }
    } else {
      console.log('No data for this portfolio');
    }
  }

  setDisplayNumber(portfolio) {
    switch (portfolio) {
      case '0':
        return '1';
      case '1':
        return '2';
      case '2':
        return '3';
      case '3':
        return '4';
      case '4':
        return '5';
      case '5':
        return '6';
    }
  }

  async cleanDataForNFL(lineupArray) {
    return lineupArray.map(entry => {
      // Create a copy of the entry object
      const updatedEntry = { ...entry };
  
      // Iterate through the lineup array and add each player's info to the entry
      entry.lineup.forEach(player => {
        updatedEntry[player.Position] = `${player.Name} (${player.Id})`;
      });
  
      // Remove the original lineup array
      delete updatedEntry.lineup;
  
      return updatedEntry;
    });
  }

  onClickShowDownloadOptions() {
    this.showDownloadOptions = true;
  }

  handleCloseDownloadOptionsModal() {
    this.showDownloadOptions = false;
  }

  async generatePortfolioLineupsCSV(download, isEntriesMode) {
    this.showDownloadOptions = false;
    console.log(this.jobData.contest);
    let fileData = [];
    if (this.jobData.sport === 'NBA') {
      fileData = this.setNbaExportData(isEntriesMode);
    } else {
      fileData = await this.setNflExportData(isEntriesMode);
    }

    const csv = this.convertArrayToCSV(fileData);

    if (download) {
      this.downloadCSV(csv, `Portfolio_Lineups_${this.jobData.contest.name}.csv`);
    } else {
      this.isCopyingCSV = true;
      setTimeout(() => {
        const textarea = document.createElement('textarea');
        textarea.value = csv;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setTimeout(() => {
          this.isCopyingCSV = false;
        }, 700);
      }, 1000);
    }
  }

  private convertArrayToCSV(array: any[]): string {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((obj) => Object.values(obj).join(',') + '\n');
    return header + rows.join('');
  }

  private downloadCSV(csv: string, filename: string) {
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async downloadSummary() {
    let extension = 'csv';
    if (this.jobData?.isSummaryZip === true) {
      extension = 'zip';
    }
    this.isDownloading = true;
    const filePath = `sim_results/${this.jobData.userId}_${this.jobData.jobId}.${extension}`;
    const fileName = `Simulation_Results_${this.jobData.contest.name}.${extension}`;

    const storageRef = ref(this.storage, filePath);

    const metadata = {
      contentDisposition: `inline; filename="${fileName}"`,
    };

    try {
      await updateMetadata(storageRef, metadata);

      // Get the download URL and initiate the download
      const url = await getDownloadURL(storageRef);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
      URL.revokeObjectURL(link.href);
      this.isDownloading = false;
    } catch (error) {
      // Handle any errors
      console.error('Error downloading file:', error);
      this.isDownloading = false;
    }
  }

  setNbaExportData(isEntriesMode) {
    let fileData = [];
    if (this.jobData.site === 'dk') {
      if (isEntriesMode) {
        fileData = this.selectedPortfolio.portfolioLineups.map((data) => ({
          ['Entry ID']: data['Entry ID'],
          ['Contest Name']: this.jobData.contest.name || 'undefined',
          ['Contest ID']: this.jobData.contest.id || 'undefined',
          ['Entry Fee']: this.jobData.contest.entryFee || 'undefined',
          PG: data.PG,
          SG: data.SG,
          SF: data.SF,
          PF: data.PF,
          C: data.C,
          G: data.G,
          F: data.F,
          UTIL: data.UTIL,
        }));
      } else {
        fileData = this.selectedPortfolio.portfolioLineups.map((data) => ({
          EntryId: data['Entry ID'],
          PG: data.PG,
          SG: data.SG,
          SF: data.SF,
          PF: data.PF,
          C: data.C,
          G: data.G,
          F: data.F,
          UTIL: data.UTIL,
          salary: data.Salary,
          originalProjection: data['Original Projection'],
          updatedProjection: data['Updated Projection'],
          avgFpts: data['Average Fpts'],
          avgROI: data['Average ROI'],
          avgRank: data['Average Rank'],
          stdFpts: data['Std Fpts'],
          stdRank: data['Std Rank'],
          winProb: data['Win Probability'],
        }));
      }
    } else {
      // fileData = this.selectedPortfolio.portfolioLineups.map((data) => ({
      //   EntryId: data['Entry ID'],
      //   PG: data.PG,
      //   PG2: data.PG2,
      //   SG: data.SG,
      //   SG2: data.SG2,
      //   SF: data.SF,
      //   SF2: data.SF2,
      //   PF: data.PF,
      //   PF2: data.PF2,
      //   C: data.C,
      //   UTIL: data.UTIL,
      //   salary: data.Salary,
      //   originalProjection: data['Original Projection'],
      //   updatedProjection: data['Updated Projection'],
      //   avgFpts: data['Average Fpts'],
      //   avgROI: data['Average ROI'],
      //   avgRank: data['Average Rank'],
      //   stdFpts: data['Std Fpts'],
      //   stdRank: data['Std Rank'],
      //   winProb: data['Win Prob'],
      // }))
    }

    return fileData;
  }

  async setNflExportData(isEntriesMode) {
    const treatedPortfolio = await this.cleanDataForNFL(this.selectedPortfolio.portfolioLineups);
    let fileData = [];
    if (this.jobData.site === 'dk') {
      if (isEntriesMode) {
        fileData = treatedPortfolio.map((data) => ({
          ['Entry ID']: data['entryId'],
          ['Contest Name']: this.jobData.contest.name || 'undefined',
          ['Contest ID']: this.jobData.contest.id || 'undefined',
          ['Entry Fee']: this.jobData.contest.entryFee || 'undefined',
          QB: data.QB,
          RB1: data.RB1,
          RB2: data.RB2,
          WR1: data.WR1,
          WR2: data.WR2,
          WR3: data.WR3,
          TE: data.TE,
          FLEX: data.FLEX,
          DST: data.DST,
        }));
      } else {
        fileData = treatedPortfolio.map((data) => ({
          EntryId: data['entryId'],
          QB: data.QB,
          RB1: data.RB1,
          RB2: data.RB2,
          WR1: data.WR1,
          WR2: data.WR2,
          WR3: data.WR3,
          TE: data.TE,
          FLEX: data.FLEX,
          DST: data.DST,
          salary: data.salary,
          // originalProjection: data['Original Projection'],
          updatedProjection: data['projection'],
          fieldProjection: data['fieldProjection'],
          avgROI: data['avgRoi'],
          top1Prob: data.top1Prob,
          // avgRank: data['Average Rank'],
          // stdFpts: data['Std Fpts'],
          // stdRank: data['Std Rank'],
          winProb: data['winProb'],
        }));
      }
    } else {
    }

    return fileData;
  }

}
