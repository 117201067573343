import { BASE_ENTRY_TABLE_SIM_COLUMNS } from "./shared-config/shared-table-data"

export const MLB_LINEUP_GEN_TABLES_OPTO = {
  DK_CLASSIC: [
    { prop: 'p1', name: 'P', tooltip: null, noId: true, filterValue: 'position p1' },
    { prop: 'p2', name: 'P', tooltip: null, noId: true, filterValue: 'position p2' },
    { prop: 'c', name: 'C', tooltip: null, noId: true, filterValue: 'position c' },
    { prop: '1b', name: '1B', tooltip: null, noId: true, filterValue: 'position 1b' },
    { prop: '2b', name: '2B', tooltip: null, noId: true, filterValue: 'position 2b' },
    { prop: '3b', name: '3B', tooltip: null, noId: true, filterValue: 'position 3b' },
    { prop: 'ss', name: 'SS', tooltip: null, noId: true, filterValue: 'position ss' },
    { prop: 'of1', name: 'OF', tooltip: null, noId: true, filterValue: 'position of1' },
    { prop: 'of2', name: 'OF', tooltip: null, noId: true, filterValue: 'position of2' },
    { prop: 'of3', name: 'OF', tooltip: null, noId: true, filterValue: 'position of3' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Lineup projection', filterValue: 'fpts' },
    { prop: 'stddev', name: 'Std Dev', tooltip: null, filterValue: 'stddev' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total salary of the Lineup", filterValue: 'salary' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
  ],
  FD_CLASSIC: [
    { prop: 'p', name: 'P', tooltip: null, noFrontId: true, filterValue: 'position p' },
    { prop: 'c1b', name: 'C/1B', tooltip: null, noFrontId: true, filterValue: 'position c1b' },
    { prop: '2b', name: '2B', tooltip: null, noFrontId: true, filterValue: 'position 2b' },
    { prop: '3b', name: '3B', tooltip: null, noFrontId: true, filterValue: 'position 3b' },
    { prop: 'ss', name: 'SS', tooltip: null, noFrontId: true, filterValue: 'position ss' },
    { prop: 'of1', name: 'OF', tooltip: null, noFrontId: true, filterValue: 'position of1' },
    { prop: 'of2', name: 'OF', tooltip: null, noFrontId: true, filterValue: 'position of2' },
    { prop: 'of3', name: 'OF', tooltip: null, noFrontId: true, filterValue: 'position of3' },
    { prop: 'util', name: 'UTIL', tooltip: null, noFrontId: true, filterValue: 'position util' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Lineup projection', filterValue: 'fpts' },
    { prop: 'stddev', name: 'Std Dev', tooltip: null, filterValue: 'stddev' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total salary of the Lineup", filterValue: 'salary' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
  ],
}

export const MLB_LINEUP_GEN_TABLES_SIM = {
  DK_CLASSIC: [
    { prop: 'p1', name: 'P', tooltip: null, noId: true, filterValue: 'position p1' },
    { prop: 'p2', name: 'P', tooltip: null, noId: true, filterValue: 'position p2' },
    { prop: 'c', name: 'C', tooltip: null, noId: true, filterValue: 'position c' },
    { prop: '1b', name: '1B', tooltip: null, noId: true, filterValue: 'position 1b' },
    { prop: '2b', name: '2B', tooltip: null, noId: true, filterValue: 'position 2b' },
    { prop: '3b', name: '3B', tooltip: null, noId: true, filterValue: 'position 3b' },
    { prop: 'ss', name: 'SS', tooltip: null, noId: true, filterValue: 'position ss' },
    { prop: 'of1', name: 'OF', tooltip: null, noId: true, filterValue: 'position of1' },
    { prop: 'of2', name: 'OF', tooltip: null, noId: true, filterValue: 'position of2' },
    { prop: 'of3', name: 'OF', tooltip: null, noId: true, filterValue: 'position of3' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections ', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg. Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },
    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  FD_CLASSIC: [
    { prop: 'p', name: 'P', tooltip: null, noFrontId: true, filterValue: 'position p' },
    { prop: 'c1b', name: 'C/1B', tooltip: null, noFrontId: true, filterValue: 'position c1b' },
    { prop: '2b', name: '2B', tooltip: null, noFrontId: true, filterValue: 'position 2b' },
    { prop: '3b', name: '3B', tooltip: null, noFrontId: true, filterValue: 'position 3b' },
    { prop: 'ss', name: 'SS', tooltip: null, noFrontId: true, filterValue: 'position ss' },
    { prop: 'of1', name: 'OF', tooltip: null, noFrontId: true, filterValue: 'position of1' },
    { prop: 'of2', name: 'OF', tooltip: null, noFrontId: true, filterValue: 'position of2' },
    { prop: 'of3', name: 'OF', tooltip: null, noFrontId: true, filterValue: 'position of3' },
    { prop: 'util', name: 'UTIL', tooltip: null, noFrontId: true, filterValue: 'position util' },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections ', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg. Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },
    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
}