import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

import setProjectionTableConfig from '../../job-utilities/set-job-projection-table';
import { BaseTableComponent } from 'src/app/components/ui-kit/base-table/base-table.component';

import { JobsService } from 'src/app/services/jobs.service';
import { StateService } from 'src/app/services/state.service';
import { Subscription } from 'rxjs';
import { setPositionFilters } from './utilities/set-position-filters';

@Component({
  selector: 'draft-group-projections',
  templateUrl: './draft-group-projections.component.html',
  styleUrls: ['./draft-group-projections.component.scss']
})
export class DraftGroupProjectionsComponent {
  @Input() jobData: any;

  @ViewChild('editInput') editInput!: ElementRef;
  @ViewChild(BaseTableComponent) draftGroupTableComponent: BaseTableComponent;
  @ViewChild('modalWrapper', { static: false }) modalWrapper!: ElementRef;

  columns: any = [];
  draftGroupReady: boolean;
  totalSelections: any;
  allRowsSelected: boolean;

  searchValue

  isCopyingCSV: boolean;
  copyCvsMessage: any;
  isLoadingTableConfig: boolean = true;

  showImportModal: boolean = false;

  subscription: Subscription;

  positionFilters: any = [];
  currentPositionFilter: string = '';

  constructor(private jobsService: JobsService, private stateService: StateService) {
    this.subscription = this.stateService.projectionDraftGroup$.subscribe(
      value => {
        if (value && value.length) {
          const selectedPlayers = value.filter((p) => p.selected);
          this.totalSelections = selectedPlayers.length;
        }
      }
    );
  }

  ngOnInit() {
    this.setProjectionsTable();
    if (this.jobData.sport !== 'PGA') {
      this.createTeamsAndMatches();
      this.createPositions();
    }
  };

  ngOnDestroy() {
    this.subscription = null;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    // if (this.showImportModal && !this.modalWrapper.nativeElement.contains(event.target)) {
    //   this.showImportModal = false;
    // }
  }

  toggleShowImportModal(event) {
    event.stopPropagation();
    this.showImportModal = !this.showImportModal;
  }

  handleCloseImportModal(event) {
    this.showImportModal = false;
  }

  updateTotalSelections() {
    this.totalSelections = this.jobData.draftGroup.filter((r) => r.selected === true).length;
  }

  async setProjectionsTable() {
    let dynamicSubType = this.jobData.jobSubType;
    if (this.jobData.sport === 'PGA') {
      if (
        (this.jobData.constest && this.jobData.contest.isCutEvent && this.jobData.jobType === 'simulator' && this.jobData.jobSubType === 'classic') ||
        (this.jobData.jobType === 'simulator' && this.jobData.isTournament && this.jobData.jobSubType === 'classic')) { dynamicSubType = `${this.jobData.jobSubType}_tournament` };
    }

    const tableConfiguration = await setProjectionTableConfig(this.jobData.sport, this.jobData.site, this.jobData.jobType, dynamicSubType);
    this.columns = tableConfiguration;
    this.totalSelections = this.jobData.draftGroup.filter((r) => r.selected === true).length;
    this.draftGroupReady = true;
    this.isLoadingTableConfig = false;
  };

  handleDataChanges() {
    // consider not doing this as it is confusing when doing one-off changes
    // this.jobData.draftGroup.sort((a, b) => b.selected - a.selected);
    this.totalSelections = this.jobData.draftGroup.filter((r) => r.selected === true).length;
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

  handleSearchPlayer(event) {
    if (event === '') {
      this.jobData.draftGroup.sort((a, b) => b.selected - a.selected);
    }
    this.draftGroupTableComponent.handleSortQuery(event);
  }

  selectAllRows(event: any) {
    const checked = event.target.checked;
    this.allRowsSelected = checked;
    this.jobData.draftGroup.forEach((row) => (row.selected = checked));
    this.handleDataChanges();
  }

  toggleRowSelection(row: any) {
    row.selected = !row.selected;
    this.handleDataChanges();
  }

  onEditComplete() {
    this.handleDataChanges();
  }

  copyCsvToClipboard() {
    this.isCopyingCSV = true;
    setTimeout(() => {
      const dataWithoutSelected = this.jobData.draftGroup.map(({ selected, BoostCount = null, BattingOrder, ...rest }) => rest);
      const csv = this.convertArrayToCSV(dataWithoutSelected);

      const textarea = document.createElement('textarea');
      textarea.value = csv;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';

      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.copyCvsMessage = 'Data Copied to Clipboard'

      setTimeout(() => {
        this.isCopyingCSV = false;
        this.copyCvsMessage = 'Fetching Data...';
      }, 1500);
    }, 1000);
  };

  private convertArrayToCSV(array: any[]): string {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((obj) => Object.values(obj).join(',') + '\n');
    return header + rows.join('');
  };

  // match filtering 
  createTeamsAndMatches() {
    const uniqueMatches = [];

    this.jobData.draftGroup.forEach((player) => {
      const matchup = {
        name: player.GameInfo.substring(0, player.GameInfo.indexOf(' '))
      };
      if (matchup && matchup.name.length) {
        const matchupExists = uniqueMatches.some(existingMatch => existingMatch.name === matchup.name);

        if (!matchupExists) {
          uniqueMatches.push(matchup);
        }
      }
    });

    this.jobData.currentMatches = uniqueMatches;
  }

  createPositions() {
    const key = `${this.jobData.sport.toLowerCase()}-${this.jobData.jobType}-${this.jobData.site}`;
    this.positionFilters = setPositionFilters(key);
  }

  onClickPosition(position) {
    this.jobData.currentSort = position;
    const sortedPlayers = this.jobData.draftGroup.sort((a, b) => {
      const aIncludesPos = a.Position.includes(position);
      const bIncludesPos = b.Position.includes(position);

      if (aIncludesPos && !bIncludesPos) {
        return -1;
      } else if (!aIncludesPos && bIncludesPos) {
        return 1;
      } else {
        return 0;
      }
    });

    this.jobData.draftGroup = sortedPlayers;
    this.stateService.setProjectionDraftGroup(sortedPlayers);
  }

  getMaxHeight(): string {
    if (this.jobData.sport === 'PGA' || this.jobData.sport === 'MMA') {
      return 'calc(-200px + 100vh)';
    } else {
      return 'calc(-270px + 100vh)';
    }
  }

}
