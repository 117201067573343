<div class="results-sub-header">
  <div class="left-wrap">
    <div class="data-links">
      <div
        class="link"
        (click)="onToggleNav('0')"
        [class.active]="activeSection === '0'"
        *ngIf="totalPortfolios > 0"
      >
        Portfolio 1 (Optimal)
      </div>
      <div
        class="link"
        (click)="onToggleNav('1')"
        [class.active]="activeSection === '1'"
        *ngIf="totalPortfolios > 1"
      >
        Portfolio 2
      </div>
      <div
        class="link"
        (click)="onToggleNav('2')"
        [class.active]="activeSection === '2'"
        *ngIf="totalPortfolios > 2"
      >
        Portfolio 3
      </div>
      <div
        class="link"
        (click)="onToggleNav('3')"
        [class.active]="activeSection === '3'"
        *ngIf="totalPortfolios > 3"
      >
        Portfolio 4
      </div>
      <div
        class="link"
        (click)="onToggleNav('4')"
        [class.active]="activeSection === '4'"
        *ngIf="totalPortfolios > 4"
      >
        Portfolio 5
      </div>
      <div
        class="link"
        (click)="onToggleNav('5')"
        [class.active]="activeSection === '5'"
        *ngIf="totalPortfolios > 5"
      >
        Portfolio 6
      </div>
    </div>
  </div>
</div>

<section class="insight-table" *ngIf="jobData.lateSwapData">
<base-table [rows]="rows" [columns]="columns" [lateSwapHeader]="lateSwapHeader"
    (downloadTable)="onClickShowDownloadOptions()" showCopy="true" [isCopyingData]="isCopyingCSV"
    (handleCopyTable)="generatePortfolioLineupsCSV(false, false)" showDownload="true"
    ></base-table>
</section>


<base-edit-overlay *ngIf="showDownloadOptions" (closeOverlay)="handleCloseDownloadOptionsModal()">
<div class="ss-upgrade-modal-wrapper">
    <div class="title">Just want your entries?</div>
    <div class="sub-title">The Entries file is formatted to be uploaded to {{jobData.site === 'dk' ? 'DraftKings' : 'FanDuel'}} without modification.
    </div>
    <button class="ss-secondary-btn mt24" (click)="generatePortfolioLineupsCSV(true, true)">Download as Entries</button>
    <button class="ss-secondary-btn mt16" (click)="generatePortfolioLineupsCSV(true, false)">Download All Data</button>
</div>
</base-edit-overlay>
