import { BASE_ENTRY_TABLE_SIM_COLUMNS } from "./shared-config/shared-table-data";

export const CBB_LINEUP_GEN_TABLES_OPTO = {
  DK_CLASSIC: [
    { prop: 'g1', name: 'G', tooltip: null, noId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noId: true, filterValue: 'position g2' },
    { prop: 'g3', name: 'G', tooltip: null, noId: true, filterValue: 'position g3' },
    { prop: 'f1', name: 'F', tooltip: null, noId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noId: true, filterValue: 'position f2' },
    { prop: 'f3', name: 'F', tooltip: null, noId: true, filterValue: 'position f3' },
    { prop: 'util1', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util1' },
    { prop: 'util2', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    // { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
  FD_CLASSIC: [
    { prop: 'g1', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g2' },
    { prop: 'f1', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f2' },
    { prop: 'c1', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c1' },
    { prop: 'c2', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    // { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
  DK_SHOWDOWN: [
    { prop: 'g1', name: 'G', tooltip: null, noId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noId: true, filterValue: 'position g2' },
    { prop: 'g3', name: 'G', tooltip: null, noId: true, filterValue: 'position g3' },
    { prop: 'f1', name: 'F', tooltip: null, noId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noId: true, filterValue: 'position f2' },
    { prop: 'f3', name: 'F', tooltip: null, noId: true, filterValue: 'position f3' },
    { prop: 'util', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util1' },
    { prop: 'util', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    // { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
  FD_SHOWDOWN: [
    { prop: 'g1', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g2' },
    { prop: 'f1', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f2' },
    { prop: 'c1', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c1' },
    { prop: 'c2', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    // { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes', filterValue: 'minutes' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'stack', name: 'Stack' },
  ],
}

export const CBB_LINEUP_GEN_TABLES_SIM = {
  DK_CLASSIC: [
    { prop: 'g1', name: 'G', tooltip: null, noId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noId: true, filterValue: 'position g2' },
    { prop: 'g3', name: 'G', tooltip: null, noId: true, filterValue: 'position g3' },
    { prop: 'f1', name: 'F', tooltip: null, noId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noId: true, filterValue: 'position f2' },
    { prop: 'f3', name: 'F', tooltip: null, noId: true, filterValue: 'position f3' },
    { prop: 'util1', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util1' },
    { prop: 'util2', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    { prop: 'fieldProjection', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldProjection' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  FD_CLASSIC: [
    { prop: 'g1', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g2' },
    { prop: 'f1', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f2' },
    { prop: 'c1', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c1' },
    { prop: 'c2', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    { prop: 'fieldProjection', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldProjection' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  DK_SHOWDOWN: [
    { prop: 'g1', name: 'G', tooltip: null, noId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noId: true, filterValue: 'position g2' },
    { prop: 'g3', name: 'G', tooltip: null, noId: true, filterValue: 'position g3' },
    { prop: 'f1', name: 'F', tooltip: null, noId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noId: true, filterValue: 'position f2' },
    { prop: 'f3', name: 'F', tooltip: null, noId: true, filterValue: 'position f3' },
    { prop: 'util1', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util1' },
    { prop: 'util2', name: 'UTIL', tooltip: null, noId: true, filterValue: 'position util2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    { prop: 'fieldProjection', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldProjection' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
  FD_SHOWDOWN: [
    { prop: 'g1', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g1' },
    { prop: 'g2', name: 'G', tooltip: null, noFrontId: true, filterValue: 'position g2' },
    { prop: 'f1', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f1' },
    { prop: 'f2', name: 'F', tooltip: null, noFrontId: true, filterValue: 'position f2' },
    { prop: 'c1', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c1' },
    { prop: 'c2', name: 'C', tooltip: null, noFrontId: true, filterValue: 'position c2' },
    { prop: 'projection', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'projection' },
    { prop: 'fieldProjection', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldProjection' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null, filterValue: 'lineupType' },
    { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
    { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

    // New columns
    { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
    { prop: 'stdDevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdDevROI' }, // new
    { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
    { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
    { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
    { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
    { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
    { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

    ...BASE_ENTRY_TABLE_SIM_COLUMNS,
  ],
}