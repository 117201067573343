const BASE_SIM_OPTIONS = {
    lineupGenerations: 5,
    topLineupsPct: 5,
    lineupSortWeights: {
        roi: 100,
        projection: 0,
        ownership: 0,
        top1Percent: 0,
        wins: 0,
    },
    totalSimulations: 50000,
    projectionMinimum: 9,
    randomness: 100,
    maxPctOffOptimal: 30,
    defaultPositionVariance: 20,
    maxPlayersPerTeam: 3,
};

export const CBB_SIM_OPTIONS = {
    CLASSIC_DK: {
        minLineupSalary: 49200,
        ...BASE_SIM_OPTIONS,
    },
    CLASSIC_FD: {
        minLineupSalary: 59200,
        ...BASE_SIM_OPTIONS,
    },
    SHOWDOWN_DK: {
        minLineupSalary: 49200,
        ...BASE_SIM_OPTIONS,
    },
    SHOWDOWN_FD: {
        minLineupSalary: 59200,
        ...BASE_SIM_OPTIONS,
    },
};



const BASE_OPTO_OPTIONS = {
    projectionMinimum: 10,
    randomness: 100,
    minutesMinimum: 0,
    numLineups: 150,
    numUniques: 1,
};

export const CBB_OPTO_OPTIONS = {
    CLASSIC_DK: {
        globalTeamLimit: 5,
        minLineupSalary: 49200,
        ...BASE_OPTO_OPTIONS,
    },
    CLASSIC_FD: {
        globalTeamLimit: 4,
        minLineupSalary: 59200,
        ...BASE_OPTO_OPTIONS,
    },
    SHOWDOWN_DK: {
        globalTeamLimit: 5,
        projectionMinimum: 5,
        minLineupSalary: 49200,
        ...BASE_OPTO_OPTIONS,
    },
    SHOWDOWN_FD: {
        globalTeamLimit: 4,
        projectionMinimum: 5,
        minLineupSalary: 59200,
        ...BASE_OPTO_OPTIONS,
    },
};


export const NBA_CORRELATION_DEFAULTS = {
    "PG": [
      { position: "PG", value: 1 },
      { position: "SG", value: -0.066989 },
      { position: "SF", value: -0.066989 },
      { position: "PF", value: -0.066989 },
      { position: "C", value: -0.043954 },
      { position: "Opp PG", value: 0.020682 },
      { position: "Opp SG", value: 0.020682 },
      { position: "Opp SF", value: 0.015477 },
      { position: "Opp PF", value: 0.015477 },
      { position: "Opp C", value: 0.000866 }
    ],
    "SG": [
      { position: "PG", value: -0.066989 },
      { position: "SG", value: 1 },
      { position: "SF", value: -0.066989 },
      { position: "PF", value: -0.066989 },
      { position: "C", value: -0.043954 },
      { position: "Opp PG", value: 0.020682 },
      { position: "Opp SG", value: 0.020682 },
      { position: "Opp SF", value: 0.015477 },
      { position: "Opp PF", value: 0.015477 },
      { position: "Opp C", value: 0.000866 }
    ],
    "SF": [
      { position: "PG", value: -0.066989 },
      { position: "SG", value: -0.066989 },
      { position: "SF", value: 1 },
      { position: "PF", value: -0.002143 },
      { position: "C", value: -0.082331 },
      { position: "Opp PG", value: 0.015477 },
      { position: "Opp SG", value: 0.015477 },
      { position: "Opp SF", value: 0.015477 },
      { position: "Opp PF", value: 0.015477 },
      { position: "Opp C", value: -0.012331 }
    ],
    "PF": [
      { position: "PG", value: -0.066989 },
      { position: "SG", value: -0.066989 },
      { position: "SF", value: -0.002143 },
      { position: "PF", value: 1 },
      { position: "C", value: -0.082331 },
      { position: "Opp PG", value: 0.015477 },
      { position: "Opp SG", value: 0.015477 },
      { position: "Opp SF", value: 0.015477 },
      { position: "Opp PF", value: 0.015477 },
      { position: "Opp C", value: -0.012331 }
    ],
    "C": [
      { position: "PG", value: -0.043954 },
      { position: "SG", value: -0.043954 },
      { position: "SF", value: -0.082331 },
      { position: "PF", value: -0.082331 },
      { position: "C", value: 1 },
      { position: "Opp PG", value: 0.000866 },
      { position: "Opp SG", value: 0.000866 },
      { position: "Opp SF", value: -0.012331 },
      { position: "Opp PF", value: -0.012331 },
      { position: "Opp C", value: -0.073081 }
    ]
  };
  