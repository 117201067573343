import { MLB_SIM_OPTIONS, MLB_OPTO_OPTIONS } from "../job-constants/MLB/mlb-option-defaults";
import { PGA_OPTO_OPTIONS, PGA_SIM_OPTIONS } from "../job-constants/PGA/pga-option-defaults";
import { NFL_OPTO_OPTIONS, NFL_SIM_OPTIONS } from "../job-constants/NFL/nfl-option-defaults";
import { MMA_OPTO_OPTIONS, MMA_SIM_OPTIONS } from "../job-constants/MMA/mma-option-defaults";
import { TEN_OPTO_OPTIONS, TEN_SIM_OPTIONS } from "../job-constants/TEN/ten-option-defaults";
import { NBA_OPTO_OPTIONS, NBA_SIM_OPTIONS } from "../job-constants/NBA/nba-option-defaults";
import { NASCAR_OPTO_OPTIONS, NASCAR_SIM_OPTIONS } from "../job-constants/NASCAR/nascar-option-defaults";

const configMap = {
  'MLB_simulator': MLB_SIM_OPTIONS,
  'MLB_optimizer': MLB_OPTO_OPTIONS,
  'PGA_simulator': PGA_SIM_OPTIONS,
  'PGA_optimizer': PGA_OPTO_OPTIONS,
  'NFL_simulator': NFL_SIM_OPTIONS,
  'NFL_optimizer': NFL_OPTO_OPTIONS,
  'MMA_simulator': MMA_SIM_OPTIONS,
  'MMA_optimizer': MMA_OPTO_OPTIONS,
  'NAS_simulator': NASCAR_SIM_OPTIONS,
  'NAS_optimizer': NASCAR_OPTO_OPTIONS,
  'NBA_simulator': NBA_SIM_OPTIONS,
  'NBA_optimizer': NBA_OPTO_OPTIONS,
  'TEN_optimizer': TEN_OPTO_OPTIONS,
  'TEN_simulator': TEN_SIM_OPTIONS,
};

async function setJobOptions(sport, site, type, subtype) {
  // Generate the key for configuration retrieval
  const key = `${sport}_${type}`;
  
  // Retrieve configuration based on the key
  const config = configMap[key];
  
  if (!config) {
    // console.log(key);
    throw new Error(`set-job-options: config not matched for ${sport}_${site}_${type}`);
  }

  let defaultConfigSettings;

  // Determine the default configuration based on subtype and site
  if (subtype === 'classic') {
    defaultConfigSettings = (site === 'dk' || site === 'ikb')
      ? config.CLASSIC_DK
      : config.CLASSIC_FD;
  } else if (subtype === 'showdown') {
    defaultConfigSettings = (site === 'dk')
      ? config.SHOWDOWN_DK
      : config.SHOWDOWN_FD;
  } else {
    throw new Error(`set-job-options: secondary site map not matched for ${sport}_${site}_${type}`);
  }

  return defaultConfigSettings;
}

export default setJobOptions;
